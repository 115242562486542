.backgroundContainer {
  background-image: url("../../../assets/images/splash.png");
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 44.9rem;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 7rem;
}

.mainText {
  color: white;
  font-size: 5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  white-space: pre-line;
  line-height: 1.1;
}

.secondaryText {
  color: white;
  font-size: 1.8rem;
  font-weight: 500;
  cursor: pointer;
}

.dot {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.searchBar {
  width: 50%;
  margin-bottom: 7rem;
}

.row {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.locationContainer {
  position: relative;
}

.locationButton {
  margin-top: 1rem;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(4px);
  border-radius: 50px;
  padding: 0.8rem 2rem;
}

.locationSkeleton {
  margin-top: 1rem;
  background: rgba(255, 255, 255, 0.45);
  backdrop-filter: blur(4px);
  border-radius: 50px;
  padding: 1.2rem 0rem;
  --base-color: rgba(255, 255, 255, 0.05);
  --highlight-color: rgba(255, 255, 255, 0.3);
}

.locationIcon {
  filter: brightness(0) invert(1);
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 62em) {
  .backgroundContainer {
    background-image: url("../../../assets/images/splash.png");
    background-position: top;
    padding-bottom: 10rem;
  }
}

@media only screen and (max-width: 48em) {
  .searchBar {
    width: 90%;
  }

  .mainText {
    font-family: "AlmoniBold";
    color: white;
    font-size: 4rem;
    font-weight: 500;
    margin-bottom: 2rem;
    text-align: center;
    white-space: pre-line;
    line-height: 1.1;
  }
}
