.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.headerGreenTitle {
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--color-main-green);
  margin: auto 0rem;
  margin-inline-end: 0.7rem;
}

.headerIcon {
  height: 2.4rem;
  width: 2.4rem;
  margin: 0.3rem;
  background-color: var(--color-light-green);
  border-radius: 3px;
  cursor: pointer;
}

@media only screen and (max-width: 48em) {
  .headerGreenTitle {
    font-size: 1.4rem;
  }
}
