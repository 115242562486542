.modalClass {
  width: 100%;
  max-width: 100%;
  padding: 0rem 10rem;
}

.backdrop {
  opacity: 0.7 !important;
  background-color: black;
}

.modalContainer {
  background-color: transparent !important;
  align-self: center;
  border: none;
}

.carousel {
  height: 90vh;
  direction: initial;
}

.exitButton,
.exitButtonIL {
  height: 2rem;
  width: 2rem;
  margin-bottom: 2rem;
  margin-inline: 21%;
  align-self: flex-end;
  cursor: pointer;
}

.mainContainer {
  align-self: center;
  margin: 0rem auto;
  background-color: white;
  align-self: center;
  width: 60%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  justify-content: flex-start;
}

.newsImage {
  max-height: 35%;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.contentContainer {
  flex: 1;
  max-height: 65%;
  width: 100%;
  padding: 0rem 2rem 0rem 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.textContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2rem 0rem 2rem 0rem;
  padding-inline-end: 3rem;
  align-items: flex-start;
  overflow: scroll;
}

.date {
  font-weight: 400;
  font-size: 1.6rem;
  color: #596475;
  margin-bottom: 0.8rem;
}

.title {
  font-weight: 600;
  font-size: 3rem;
  color: #596475;
  margin-bottom: 1.6rem;
  text-align: start;
}

.subtitle {
  font-weight: 400;
  font-size: 2rem;
  color: #596475;
  margin-bottom: 0.8rem;
  text-align: start;
}

.tags {
  font-weight: 400;
  font-size: 1.8rem;
  color: var(--color-main-green);
  margin-bottom: 1rem;
}

.description {
  flex: 1;
  font-weight: 400;
  font-size: 1.6rem;
  color: #596475;
  text-align: start;
  white-space: pre-line;
}

.buttonContainer {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
}

.veganActiveLogo {
  display: flex;
  width: 20rem;
  height: 10rem;
  margin-bottom: 4rem;
  margin-top: auto;
}

.veganActiveLogoMobile {
  display: none !important;
}

.previousButton,
.nextButton {
  display: flex;
  max-width: 17%;
  padding: 1rem 2rem;
  height: min-content;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0rem;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3.5px);
  border-radius: 20px;
  z-index: 9;
}

.previousButton {
  left: 0;
}

.nextButton {
  right: 0;
}

.buttonText {
  display: flex;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 21px;
  color: #ffffff;
  text-align: center;
}

.directions {
  direction: rtl;
}

.mobileButton {
  display: none;
}

.buttonImage {
  display: none !important;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .modalClass {
    width: 100%;
    max-width: 100%;
    padding: 0rem 3rem 5rem 3rem;
  }

  .exitButton,
  .exitButtonIL {
    margin-top: 2rem;
    margin-inline: 2%;
  }

  .buttonContainer {
    display: none;
  }

  .contentContainer {
    max-height: 100%;
    padding: 0rem 2rem 0rem 2rem;
    overflow: hidden;
  }

  .mainContainer {
    height: 75vh;
    width: 98%;
    margin-bottom: 6rem;
  }

  .textContainer {
    max-height: 100%;
    padding: 2rem 0rem 2rem 0rem;
    padding-inline-end: 0rem;
  }

  .mobileButton {
    display: flex;

    width: 100%;
    margin-bottom: 2rem;
  }

  .veganActiveLogoMobile {
    display: flex !important;
    margin-top: auto;
    width: 20rem;
    height: 10rem;
    margin-top: 5rem;
  }

  .buttonText {
    display: none;
  }

  .buttonImage {
    display: flex !important;
  }

  .previousButton,
  .nextButton {
    max-width: auto;
    padding: 0rem 0rem;
    height: auto;
    position: absolute;
    top: auto;
    bottom: 0;
    margin: 0rem 0rem;
    cursor: pointer;
    background: none;
    backdrop-filter: inherit;
    border-radius: 0px;
    z-index: 9;
  }

  .previousButton {
    bottom: 0;
    right: 0;
    left: auto;
    margin-inline-end: 6rem;
  }

  .nextButton {
    bottom: 0;
    right: 0;
    left: auto;
  }
}
