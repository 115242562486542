.infoWindowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 2rem 0rem 0rem;
  line-height: 1.35;
  overflow: hidden;
  white-space: nowrap;
  width: max-content;
  cursor: pointer;
}

.infoWindowImg {
  height: 5rem;
  width: 5rem;
  margin-bottom: auto;
  border-radius: 5px;
}

.contentContainer {
  height: max-content;
  width: 20rem;
  max-width: 15rem;
  margin-inline-start: 1rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.infoWindowName {
  font-weight: 600;
  font-size: 1.6rem;

  color: #586576;
}

.infoWindowAddress {
  font-weight: 400;
  font-size: 1.4rem;
  color: #838991;
  white-space: nowrap;
  overflow: hidden;
}

.categoryContainer,
.categoriesContainer,
.distanceRow {
  display: flex;
  flex-direction: row;
}

.distanceRow {
  gap: 0.3rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

.distance {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 17px;
  color: #596475;
}

.categoriesContainer {
  width: 100%;
  flex-wrap: wrap;
  max-height: 1.5rem;
  overflow: hidden;
  margin-bottom: 0.2rem;
}

.restCategory {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-main-green);
  white-space: nowrap;
}

.categoryDot {
  font-size: 1rem;
  color: var(--color-main-green);
  margin: 0rem 0.3rem;
  align-self: center;
}

.categoryContainer:first-child p:first-child {
  display: none;
}
