.mainContainer {
  width: 100%;
  display: inline-block;
  margin-bottom: 5rem;
}

.reviewContainer {
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.row,
.headerContainer,
.bottomRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.reviewerImage {
  width: 10%;
  max-width: 10%;
  padding-inline-end: 1rem;
  object-fit: cover;
}

.reviewerTag {
  font-weight: 400;
  font-size: 1.4rem;
  color: #838991;
}

.ratingTag {
  width: min-content;
  display: flex;
  flex-direction: row;
  gap: 0.8rem;
  padding: 0.4rem 0.8rem;
  background: #fff4d7;
  border-radius: 50px;
  align-self: flex-start;
  margin-inline-start: auto;
}

.reviewTags {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-main-green);
  padding-bottom: 0.6rem;
}

.ratingTagText {
  font-weight: 400;
  font-size: 1.4rem;
  white-space: nowrap;
}

.reviewerDetails {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 0.8rem;
}

.reviewerName {
  margin-top: 0.3rem;
  font-weight: 600;
  font-size: 1.73rem;
  color: #838990;
  word-wrap: break-word;
}

.description {
  width: 100%;
  min-height: 3rem;
  font-weight: 400;
  line-height: 1.2;
  font-size: 1.5rem;
  color: #5f6673;
  margin-bottom: 0.8rem;
  flex-wrap: wrap;
  word-wrap: break-word;
  overflow: hidden;
}

.bottomRow {
  align-items: flex-end;
}

.voteCount {
  font-weight: 600;
  font-size: 1.15rem;
  color: #596475;
  line-height: 0.8;
  padding-inline-start: 0.2rem;
}

.daysAgo {
  font-weight: 400;
  font-size: 1.15rem;
  color: #838990;
  line-height: 0.8;
}

.imagesGrid {
  display: grid;
  height: 14rem;
  max-height: 14rem;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.4rem;
}

.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  min-width: 0;
  min-height: 0 !important;
  display: flex;
  border-radius: 12px;
  background-color: gainsboro;
}

.imagesGrid > :nth-child(2) {
  grid-column: 2;
}

.uploadedImage {
  height: 100%;
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.imageContainer:first-child {
  grid-row: 1 / 3;
}

.showMoreImagesContainer {
  display: block;
  position: absolute;
  background-color: rgba(1, 1, 1, 0.5);
  border-radius: 10px;
  opacity: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
}

.showMoreText {
  position: absolute;
  color: white;
  text-align: center;
  opacity: 1;
  right: 38%;
  top: 31%;
  font-weight: 600;
  font-size: 2.4rem;
  color: #ffffff;
}

.imagesGrid > :nth-child(3) > :nth-child(2) {
  display: flex;
}

.test {
  width: 100%;
  height: 18rem;
}

.mobileContainer {
  position: absolute;
  display: flex;
  width: 100%;
  width: 100vw;
  overflow: scroll;
  gap: 1rem;
  left: 0;
  right: 0;
}

.pendingTag {
  width: min-content;
  margin-top: 0.2rem;
  padding: 0.4rem 1.2rem;
  background-color: #ff527a;
  color: white;
  border-radius: 50px;
  margin-inline-start: 0.7rem;
}

@media only screen and (max-width: 48em) {
  .uploadedImage {
    height: 18rem;
    min-width: 70%;
    width: 70%;
    max-height: 18rem;
    object-fit: cover;
    border-radius: 10px;
  }
  .uploadedImage:first-child {
    margin-inline-start: 5.5rem;
  }
  .uploadedImage:last-child {
    margin-inline-end: 2rem;
  }
}
