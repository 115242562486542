.mainContainer,
.mainContainerAboutUs {
  display: flex;
  width: 100%;
  background-image: url("../../../assets/images/app_component_cover.webp");
  justify-content: center;
  align-items: flex-end;
  padding: 0rem 1rem;
  padding-top: 5rem;
  margin-bottom: 7rem;
}

.mainContainerAboutUs {
  background-position: bottom; /* Center the image */
  margin-bottom: 0rem;
}

.contentWrapper,
.contentWrapperAboutUs {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
}

.imageContainer {
  margin-inline-start: 10rem;
  margin-bottom: -3rem;
}

.imageContainerAboutUs {
  margin-top: -10rem;
}

.textContainer,
.textContainerAboutUs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 7rem;
}
.textContainerAboutUs {
  padding-top: 4rem;
}

.title {
  font-family: "AlmoniBold";
  font-weight: 700;
  font-size: 3.6rem;
  color: white;
  padding-bottom: 0.5rem;
}

.description {
  font-weight: 400;
  font-size: 1.8rem;
  color: white;
  white-space: pre-line;
  line-height: 1.2;
  padding-bottom: 0.5rem;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
}

.downloadButton {
  height: 7rem;
  width: min-content;
  margin-top: 1rem;
  object-fit: fill;
  cursor: pointer;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer,
  .mainContainerAboutUs {
    display: flex;
  }

  .textContainer {
    padding-top: 3rem;
    align-items: center;
  }

  .textContainerAboutUs {
    padding-top: 0rem;
    padding-bottom: 6rem;
    align-items: center;
  }

  .imageContainer {
    width: 60%;
    margin: 3rem 15% -3rem auto;
  }

  .imageContainerAboutUs {
    margin-top: -10rem;
  }

  .contentWrapper,
  .contentWrapperAboutUs {
    display: flex;
    flex-direction: column-reverse;
    gap: 1rem;
  }

  .contentWrapperAboutUs {
    flex-direction: column;
  }

  .title {
    text-align: center;
  }

  .description {
    text-align: center;
  }

  .buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .downloadButton {
    width: 45%;
  }

  .downloadButton img {
    width: 100%;
  }
}
