.mainContainer {
  position: relative;
  width: 100%;
  padding-bottom: 2rem;
  margin-bottom: 1.5rem;
}

.background {
  position: absolute;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  background-color: white;
  padding: 2.4rem 0rem;
  margin: 0rem auto;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
  z-index: -1;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  padding-inline: var(--mobile-padding-inline);
}

.headerText {
  font-weight: 600;
  font-size: 2.2rem;
  margin-top: 2rem;
  margin-bottom: 3rem;
  color: #596475;
  margin: 2rem 3rem 3rem 2rem;
}

.rowContainer {
  gap: 3rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow: scroll;
  padding-bottom: 3rem;
  padding-inline: 3rem;
}

.entityContainer {
  width: 50%;
  min-width: 27rem;
  box-shadow: none;
  background-color: white !important;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
  border-radius: 14px;
}

.seeAllBtn {
  width: 86%;
  padding: 0.7rem 0rem;
  margin: 0rem auto;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .background {
    width: 90%;
  }
}
