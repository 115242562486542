.mainContainer {
  position: relative;
  height: 6.6rem;
}

.inputRow,
.inputRowError,
.inputRowSelected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 5.5rem;
  width: 100%;
  padding: 1.2rem;
  background-color: #f3f4fb;
  border-radius: 10px;
  margin-bottom: 0.8rem;
  cursor: pointer;
}

.inputRowSelected {
  background-color: var(--color-light-green);
}

.input,
.inputSelected {
  width: 100%;
  align-self: center;
  font-size: 1.6rem;
  font-weight: 400;
  color: #a9a9a9;
  cursor: pointer;
  caret-color: transparent;
  background-color: transparent;
}

.inputSelected {
  color: #596475;
  font-weight: 500;
}

.inputRowError {
  background-color: #ffdfe6;
}

.input::placeholder {
  color: #b9c3ce;
}

.wrapper {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem 0rem;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
}

.result {
  padding: 1rem 2rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: #596475;
}

.result:hover {
  background: #f3f4fb;
}

.errorMessage {
  color: #ff4a4a;
  font-weight: 400;
  font-size: 1.4rem;
}
