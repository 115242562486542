.mainContainer {
  background-color: transparent !important;
  align-self: center;
  border: none;
}

.contentContainer {
  background-color: #fff5eb;
  align-self: center;
  width: 90%;
  max-width: 60rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 0rem 10rem 2rem 10rem;
  justify-content: space-between;
}

.cowImg {
  height: 15rem;
  width: auto;
  margin-top: -3rem;
  margin-bottom: 2.4rem;
}

.title {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 24px;
  color: #596475;
  margin-bottom: 0.8rem;
}

.subTitle {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 17px;
  color: #838991;
  margin-bottom: 1.6rem;
}

.button {
  margin-top: 0.3rem;
  width: 70%;
}

.backToHome {
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  color: #596475;
  margin-top: 1.6rem;
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 48em) {
  .contentContainer {
    background-color: #fff5eb;
    align-self: center;
    width: 90%;
    max-width: 60rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    padding: 0rem 0rem 2rem 0rem;
    justify-content: space-between;
  }
}
