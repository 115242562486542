.containerWrapper {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #f3f4fb;
  padding: 0;
  width: 100%;
}
.container {
  padding-inline: var(--common-padding-inline);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.mainTitle {
  color: var(--color-text, #596475);
  font-size: 3rem;
  line-height: 3rem !important;
  font-weight: 600;
  z-index: 10;
  width: 70%;
}
.outroContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.outroText {
  font-size: 1.5rem;
  font-weight: 400;
  color: #596475;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch; /* Ensures all cards stretch to the same height in a row */
  gap: 20px;
}
/*  */
.cardContainer {
  display: flex;
  flex-direction: column;
  border-radius: 14px;
  background-color: #fff;
  box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.1);
  transition: all 0.3s;
  flex: 1; /* or use a fixed width if you prefer */
  max-width: 30%; /* same as you already have */
  margin-bottom: 1rem;
}
.cardContainer:hover {
  box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.2);
  transform: scale(1.05);
}
.photo {
  border-radius: 15px 15px 0 0;
  width: 100%;
  height: 75px; /* Adjust the value as needed */
}
.photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cardTitle {
  font-size: 2rem;
  font-weight: 600;
  color: #596475;
  align-self: center;
}
.cardDescription {
  font-size: 1.5rem;
  font-weight: 400;
  color: #838990;
  align-self: flex-start;
}
.cardContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Fills all available vertical space */
  justify-content: flex-start;
  gap: 1.5rem; /* keep your desired gap */
  padding: 1rem 2rem;
}
.categoriesContainer {
  display: flex;
  align-self: flex-start;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.cardIcon {
  font-size: 2rem;
  color: #000;
}
.cardCategoryRow {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}
.cardCategoryText {
  color: #596475;
  font-size: 1.5rem;
  font-weight: 400;
}

.link {
  color: #5c8a2d;
  text-decoration: underline;
}
.link:hover {
  color: #81bc45;
  text-decoration: underline;
}
.button {
  margin-top: auto;
  display: inline-flex;
  width: auto;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 23px;
  background: var(--main-green, #81bc45);
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  width: 70%;
  align-self: center;
}
@media (max-width: 991px) {
  .container {
    padding-inline: var(--common-padding-inline);
    display: flex;
    flex-direction: column;
    margin: 2rem auto;
    width: 90%;
    gap: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .cardContainer {
    border-radius: 14px;
    margin-bottom: 1rem;
    transition: all 0.3s;
    background-color: #fff;
    /* min-height: 300px; */
    box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.1);
    display: flex;
    flex-direction: column;
    /* margin: auto; */
    max-width: 100%;
  }
  .cardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
  }
  .mainTitle {
    color: var(--color-text, #596475);
    font-size: 3rem;
    line-height: 3rem !important;
    font-weight: 600;
    z-index: 10;
    width: 70%;
    margin: auto;
    text-align: center;
  }
  .outroContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .photo {
    border-radius: 15px 15px 0 0;
    width: 100%;
    height: 100px; /* Adjust the value as needed */
  }
}
