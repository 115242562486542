.mainContainer {
  width: 100%;
  padding-inline: var(--mobile-padding-inline);
  margin-bottom: 6rem;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 2.5rem 0rem;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: inherit;
}

.headerTitle {
  font-weight: 600;
  font-size: 2.4rem;
  color: var(--color-text);
  margin-inline-end: 0.9rem;
}

.headerGreenTitle {
  font-weight: 400;
  font-size: 12rem;
  color: var(--color-main-green);
  margin-inline-end: 7rem;
}

.headerIcon {
  height: 2.44rem;
  width: 2.44rem;
  margin: 0.3rem;
  background-color: var(--color-light-green);
  border-radius: 3px;
  padding: 0.3rem;
}

.headerIcon:hover {
  opacity: 0.8;
  cursor: pointer;
}

.postsGrid {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1.7rem;
}

.postContainer {
  position: relative;
  height: 100%;
  width: 100%;
  max-height: 100%;
  display: flex;
  grid-template: 1fr / 1fr;
  place-items: center;
  aspect-ratio: 1/1;
}

.postContainer > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.postContainer:first-child {
  grid-row: 1 / 3;
}

.post {
  z-index: 1;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.instagramIcon {
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  opacity: 0;
  object-fit: scale-down;
  background-color: rgba(1, 1, 1, 0.2);
}

.instagramIcon:hover {
  opacity: 1;
  cursor: pointer;
}

.mediaButtonsTop {
  display: flex !important;
}

.mediaButtonsBottom {
  display: none !important;
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 62em) {
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mediaButtonsTop {
    display: none !important;
  }

  .mediaButtonsBottom {
    width: 100%;
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    margin-top: 2rem;
  }
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .postContainer:first-child {
    grid-column: 1 / 3;
    grid-row: 0 /1;
  }

  .postsGrid {
    width: 100%;
    margin: 0rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1.7rem;
  }
}
