.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5.6rem 0rem;
  padding-inline: var(--common-padding-inline);
  /* background-image: url("../../../assets/images/about_va_background.svg"); */
  background-color: #d9ebc730;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainTitle {
  width: 50%;
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
  padding-bottom: 0.8rem;
  text-align: center;
}

.mainSubTitle {
  width: 70%;
  font-weight: 400;
  font-size: 2rem;
  color: #596475;
  text-align: center;
}

.projectsSubtitle {
  width: 100%;
  font-weight: 600;
  font-size: 2.2rem;
  color: #596475;
  text-align: center;
  margin-top: 4rem;
  white-space: pre-wrap;
}

.projectsLink {
  width: 100%;
  font-weight: 600;
  font-size: 2rem;
  color: var(--color-main-green);
  text-align: center;
  margin-top: 1rem;
}

.projectsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5.8rem;
}

.projectsTitle {
  text-align: center;
  font-weight: 600;
  font-size: 2.2rem;
  margin-bottom: 2rem;
  color: #596475;
}

.campaignsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.campaignContainer {
  max-width: 30%;
  background-color: white;
  border-radius: 20px;
}

.campaignImg {
  width: 100%;
  min-height: 2.4rem;
  height: 24.7rem;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.campaignContent {
  display: flex;
  flex-direction: column;
  padding: 1.6rem;
}

.campaignSupportersContainer {
  width: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #d9ebc7;
  padding: 0.8rem 1.6rem;
  border-radius: 50px;
  margin-bottom: 1.2rem;
}

.campaignSupportersText {
  font-weight: 600;
  font-size: 1.8rem;
  white-space: nowrap;
}

.campaignTitle {
  font-weight: 600;
  font-size: 2.2rem;
  color: #586576;
  margin-bottom: 0.8rem;
}

.campaignDescription {
  font-weight: 400;
  font-size: 1.6rem;
  color: #586576;
  margin-bottom: 0.8rem;
}

.campaignLink {
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--color-main-green);
  text-decoration: none;
}

.benefitsTitle {
  font-weight: 600;
  font-size: 2.2rem;
  margin-bottom: 0.4rem;
  color: #596475;
}

.benefitsSubtitle {
  font-weight: 400;
  font-size: 2rem;
  color: #596475;
  text-align: center;
  padding-inline: var(--mobile-padding-inline);
}

.benefitsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  margin-top: 5.8rem;
}

.benefitsRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 3rem 0rem 4rem 0rem;
  gap: 4rem;
}

.benefitContainer {
  flex: 1 1 0px;
}

.seeAllBenefits {
  font-weight: 600;
  font-size: 2.2rem;
  line-height: 27px;

  color: var(--color-main-green);
  cursor: pointer;
}

@media only screen and (max-width: 48em) {
  .mainContainer {
    padding: 5rem 0rem 5rem 0rem;
    background-image: none;
    background-color: rgba(217, 235, 199, 0.2);
    padding-inline: var(--mobile-padding-inline);
  }
  .benefitsRow {
    overflow: scroll;
    padding-inline: var(--mobile-padding-inline);
  }

  .benefitContainer {
    flex: none;
    width: 75%;
  }

  .mainTitle {
    width: 100%;
    padding-inline: var(--mobile-padding-inline);
  }
  .mainSubTitle {
    width: 100%;
    gap: 5.4rem;
    padding-inline: var(--mobile-padding-inline);
  }

  .campaignContainer {
    width: 100%;
    max-width: 100%;
  }

  .campaignsRow {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-inline: var(--mobile-padding-inline);
  }

  .projectsSubtitle {
    width: 100%;
    font-weight: 600;
    font-size: 2rem;
    color: #596475;
    text-align: center;
    margin-top: 4rem;
  }
}
