.container {
  padding-inline: calc(var(--common-padding-inline) - 5.6rem);
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
  background-image: url("../../../../assets/images/bg_dotted.png");
  background-size: cover;
  background-position: 50% 50%;
  z-index: 1;
  position: relative; /* Ensure relative positioning to contain the absolutely positioned image */

}
.flowchartContainer {
  display: grid;
  grid-template-columns: 35% 10% 35%; /* Fixed widths to maintain gap consistency */
  column-gap: 4rem; /* Adjust as needed */
  row-gap: 0;
  align-items: flex-start;
  width: 100%;
  justify-content: center; /* Centers the entire grid */
}

.numberBubbleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-column: 2; /* Always center the bubble in the second column */
}

.textBubbleLeft {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  text-align: left;
  grid-column: 1; /* Text on the left */
  width: 100%;
  align-self: flex-start;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 19px;
}

.textBubbleRight {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  grid-column: 3; /* Text on the right */
  width: 100%;
  align-self: flex-start;
  box-shadow: -20px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 19px;
}
.textBubbleContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 19px;
  background-color: fff;
  transition: background-color 0.3s;
  flex-direction: column;
  box-shadow: 20px 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 19px;
}
.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 3;
}
.textBubbleTitleContainer {
  padding: 20px;
  width: 100%;
  border-radius: 19px 19px 0px 0px;
  background: linear-gradient(116deg, #a1e161 1.74%, #72af35 79.19%);
  z-index: 990;
  text-align: left;
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
}
.textBubbleTitleContainerLast {
  padding: 20px;
  width: 100%;
  border-radius: 19px 19px 0px 0px;
  background: linear-gradient(142deg, #f9708f 15.14%, #c63456 89.33%);
  z-index: 990;
  text-align: left;
}
.textBubbleDescription {
  display: flex;
  flex-direction: row;
  padding: 20px;
  width: 100%;
  border-radius: 0px 0px 19px 19px;
  background-color: #fff;
  z-index: 990;
  text-align: left;
}
.pointerArrowRTL {
  --r: 7px; /* Corner radius */
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -6px;
  margin-right: -30px;
  transform: rotate(90deg);
  width: 50px;
  aspect-ratio: 1 / cos(30deg);
  background: white;
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  filter: drop-shadow(15px 5px 5px rgba(0, 0, 0, 1));
  mask:
      /* Conic gradient to fill the middle area */ conic-gradient(
        from -30deg at 50% calc(200% - 3 * var(--r) / 2),
        #000 60deg,
        #0000 0
      )
      0 100% / 100% calc(100% - 3 * var(--r) / 2) no-repeat,
    /* Radial gradients for the corners */
      radial-gradient(var(--r) at 50% calc(2 * var(--r)), #000 98%, #0000 101%),
    radial-gradient(
      var(--r) at left calc(tan(60deg) * var(--r)) bottom var(--r),
      #000 98%,
      #0000 101%
    ),
    radial-gradient(
      var(--r) at right calc(tan(60deg) * var(--r)) bottom var(--r),
      #000 98%,
      #0000 101%
    );
}
.pointerArrowLTR {
  --r: 7px; /* Corner radius */
  position: absolute;
  top: 0;
  left: 0;
  margin-top: -6px;
  margin-left: -30px;
  transform: rotate(-90deg);
  width: 50px;
  aspect-ratio: 1 / cos(30deg);
  background: white;
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
  filter: drop-shadow(15px 5px 5px rgba(0, 0, 0, 1));
  mask:
      /* Conic gradient to fill the middle area */ conic-gradient(
        from -30deg at 50% calc(200% - 3 * var(--r) / 2),
        #000 60deg,
        #0000 0
      )
      0 100% / 100% calc(100% - 3 * var(--r) / 2) no-repeat,
    /* Radial gradients for the corners */
      radial-gradient(var(--r) at 50% calc(2 * var(--r)), #000 98%, #0000 101%),
    radial-gradient(
      var(--r) at left calc(tan(60deg) * var(--r)) bottom var(--r),
      #000 98%,
      #0000 101%
    ),
    radial-gradient(
      var(--r) at right calc(tan(60deg) * var(--r)) bottom var(--r),
      #000 98%,
      #0000 101%
    );
}
.bubbleTitle {
  color: var(--white, #fff);
  /* Body txt */
  font-family: "AlmoniRegular";
  font-size: 2.3rem;
  font-style: normal;
  font-weight: 400;
}
.bubbleDescription {
  color: var(--secondary-grey, #838990);
  /* Body txt */
  font-family: "AlmoniRegular";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 113.636% */
}
.sectionTitle {
  color: var(--main-beet, #ff527a);

  /* Header */
  font-family: "AlmoniRegular";
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
}
.sectionSubtitle {
  color: var(--color-text, #596475);

  /* Body txt */
  font-family: "AlmoniRegular";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
}

.cardItemContainer {
  height: 500px;
  width: 500px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}
.contentContaienr {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.upperLip {
  width: 80%;
  height: 20px;
  background-color: red;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bottomLop {
  width: 80%;
  height: 20px;
  background-color: red;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
@media (max-width: 991px) {
  .container {
    padding-inline: 5%;
  }
}
