.mainContainer,
.mainContainerGreen {
  width: 100%;
  display: flex;

  flex-direction: row;
  border-radius: 20px;
  padding: 1.2rem 2rem;
  margin-bottom: 4rem;
  cursor: pointer;
  background-image: url("../../../assets/images/banner/orange_background_web.png");
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  justify-content: center;
}
.icon,
.iconGreen {
  height: auto;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-inline-start: 2rem;
}

.title {
  font-weight: 400;
  font-size: 2.4rem;
  color: #ff527a;
  white-space: pre-wrap;
}

.subtitle,
.subtitleGreen {
  font-weight: 500;
  font-size: 1.6rem;
  color: white;
  background-color: #ff527a;
  border-radius: 50px;
  padding: 0.7rem 1.4rem;
}

.contentContainerGreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline-end: 11.6rem;
  cursor: pointer;
  margin-top: 1.5rem;
}

.titleGreen {
  font-weight: 400;
  font-size: 2.2rem;
  color: #ff527a;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 0.5rem;
  cursor: pointer;
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    width: 90%;
    margin: 2rem auto;
    flex-direction: column;
    padding: 0rem 2rem 2rem 2rem;
    margin-bottom: 3rem;
    cursor: pointer;
    align-items: center;
    background-image: url("../../../assets/images/banner/orange_background_mobile.png");
  }

  .icon {
    width: 80%;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }

  .vfLogo {
    display: none;
  }

  .contentContainer {
    margin-top: 0rem;
    padding-inline-end: 0;
    align-items: center;
    margin-inline-start: 0rem;
  }

  .subtitle {
    font-size: 1.8rem;
  }
}

.mainContainerGreen {
  width: 90%;
  margin: 2rem auto;
  flex-direction: column;
  padding: 0rem 2rem 2rem 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  align-items: center;
  background-image: url("../../../assets/images/banner/orange_background_mobile.png");
}

.mainContainerGreen {
  background-image: url("../../../assets/images/banner/green_background_mobile.png");
}

.iconGreen {
  width: 80%;
  height: auto;
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.vfLogoGreen {
  display: none;
}

.contentContainerGreen {
  padding-inline-end: 0;
}

.titleGreen {
  font-size: 20px;
  color: white;
}

.subtitleGreen {
  color: var(--color-main-green);
  background-color: white;
  font-size: 1.6rem;
  padding: 0.5rem 1.4rem;
}
