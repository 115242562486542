.card {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 14px;
  height: 15rem;
  box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.1);
}

.cardImageGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  z-index: 990;
}

.cardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  /* filter: brightness(1.5); */
  top: 0;
  left: 0;
}

.cardContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  z-index: 2;
}

.cardOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%; /* Increased height for more space */
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.1s ease-in-out;
  z-index: 991;
}

.card:hover .cardOverlay {
  transform: translateY(100%);
}

.cardTitle {
  position: absolute;
  bottom: 10%; /* Keep text positioned correctly */
  transform: translate(0%, 15%); /* Center the text based on its middle */
  width: 100%;
  text-align: center;
  color: #596475;
  font-weight: 400;
  font-size: 2rem; /* Slightly reduced font size */
  transition: color 0.3s ease, font-weight 0.3s ease;
  padding: 10px;
  z-index: 992;
}

.card:hover .cardTitle {
  color: white;
  font-weight: 700;
}
