.imagesContainer {
  direction: ltr;
}

.cover {
  width: 100%;
  height: 30rem;
  border-radius: 10px;
}

.noImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../../assets/images/default_restaurant_cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3rem 2rem;
  border-radius: 20px;
}

.defaultImage {
  width: 13rem;
  height: auto;
}

.defaultImageText {
  margin-top: 2rem;
  font-weight: 400;
  font-size: 3.6rem;
  text-align: center;
  color: #ffffff;
}

.grid1,
.grid2,
.grid3,
.grid4,
.grid5,
.grid6,
.grid7 {
  width: 100%;
  height: 30rem;
  border-radius: 10px;
  overflow: hidden;
}

.grid2 {
  display: flex;
  flex-direction: row;
  flex: 1 1 0px;
  gap: 0.5rem;
}

.grid4 {
  height: 31rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 0.5rem;
  overflow: auto;
}

.grid4 .gridItem {
  height: 10rem;
}

.grid4 .gridItem:first-child {
  height: 31rem;
  grid-row: 1 / 4;
}

.grid3,
.grid5,
.grid6 {
  height: 30.5rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  overflow: auto;
  gap: 0.5rem;
}

.grid5,
.grid6 {
  grid-template-columns: 2fr 1fr 1fr;
}

.grid3 .gridItem,
.grid5 .gridItem,
.grid6 .gridItem {
  height: 15rem;
}
.grid3 .gridItem:first-child,
.grid5 .gridItem:first-child,
.grid6 .gridItem:first-child {
  height: 30.5rem;
  grid-row: 1 / 3;
}

.grid6 .gridItem:last-child .showMoreImagesContainer {
  display: block;
}

.gridItem {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.images {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.showMoreImagesContainer {
  display: block;
  position: absolute;
  background-color: rgba(1, 1, 1, 0.5);
  border-radius: 10px;
  opacity: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
}

.showMoreText {
  position: absolute;
  color: white;
  text-align: center;
  opacity: 1;
  right: 43%;
  top: 38%;
  font-weight: 600;
  font-size: 4rem;
  color: #ffffff;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .cover {
    width: 100%;
    height: 25rem;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }

  .noImageContainer {
    background-image: url("../../../assets/images/default_restaurant_cover_mobile.png");
  }

  .defaultImageText {
    font-size: 2.6rem;
  }
}
