.callToAction {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 507px;
  max-width: 100%;
  flex-direction: column;
  color: var(--Green-txt, #5c8a2d);
}

.callToActionWrapper {
  border-radius: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.1);

}

.callToActionInner {
  font-size: 2.5rem;
  border-radius: 15.724px;
  background: var(--light-green, #effce3);
  padding:15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 400;
}

.callToActionInner:hover {
  background: #e0f7d4;
}

.callToActionInner:focus {
  outline: 3px solid #5c8a2d;
  outline-offset: 2px;
}

@media (max-width: 991px) {
  .callToAction {
  }
  .callToActionInner {
    max-width: 100%;
    padding: 20px 20px;
  }
}
