.fileUploader {
  width: 100%;
  height: 100%;
  display: flex;
  cursor: pointer;
}

.dropArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: dashed 1px black;
  border-radius: 10px;
  gap: 1rem;
  align-items: center;
  cursor: pointer;
}

.cameraIcon {
}

.addText {
  font-weight: 600;
  font-size: 1.2rem;
  color: #596475;
  white-space: nowrap;
}
