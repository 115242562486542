.modalContainer {
  width: 100%;
  background-color: transparent !important;
  align-self: center;
  border: none;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 95%;
  background-color: white;
  border-radius: 20px;
  margin: 0rem auto;
  min-height: 10rem;
  padding: 0rem 0.8rem;
  padding-bottom: 2.4rem;
  align-items: center;
  margin-bottom: 2rem;
}

.header {
  width: 100%;
  text-align: center;
  margin-top: 2.4rem;
  margin-bottom: 0.8rem;
  font-weight: 600;
  font-size: 1.6rem;
  color: #596475;
}

.tagsContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.4rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-self: flex-start;
}

.searchBarContainer {
  width: 60%;
  align-self: center;
  margin-top: 4rem;
  margin-bottom: 8rem;
}

.searchBar {
  background-color: #f3f4fb;
}

.locationBar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 10rem;
}

.entityTags {
  flex: 1;
}

.tagHeader {
  padding-inline-start: 0.5rem;
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #596475;
  align-self: flex-start;
}

.categoriesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.clearFilters {
  margin-top: 2.8rem;
  font-weight: 400;
  font-size: 1.6rem;
  text-align: center;
  color: var(--color-main-green);
  cursor: pointer;
}

.showResults {
  margin-top: 2rem;
  width: min-content;
}

.exitButton {
  margin: 2rem;
  height: 2rem;
  align-self: flex-end;
}
