.container {
  border-radius: 0;
  display: flex;
  /* padding: 50px 0 0; */
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.infoCard {
  border-radius: 30px;
  background-color: #ffdfe6;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 50px 35px;
  box-sizing: border-box;
}

.contentWrapper {
  z-index: 10;
  margin-top: -90px;
  width: 100%;
}

.headerSection {
  display: flex;
  width: 100%;
}

.imageColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 17%;
  min-width: 130px;
  margin-left: 0;
}

.trademarkLogo {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: auto;
  flex-grow: 1;
}

.titleColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 20px;
  align-self: flex-end;
}

.title {
  color: var(--color-text, #596475);
  margin-top: 103px;
  font: 600 3.5rem/1.2 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.description {
  color: var(--color-text, #596475);
  margin: 8px 0 0 20px;
  font: 400 22px/1.4 AlmoniRegular, -apple-system, Roboto, Helvetica, sans-serif;
  max-width: 100%;
}

@media (max-width: 991px) {
  .container {
    padding: 67px 20px 0;
  }

  .infoCard {
    padding: 0 30px 47px;
  }

  .contentWrapper {
    max-width: 100%;
  }

  .headerSection {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .imageColumn {
    width: auto;
  }

  .titleColumn {
    width: 100%;
    margin-left: 0;
  }

  .title {
    margin-top: 20px;
    font-size: 32px;
    text-align: center;
  }

  .description {
    margin: 20px 0 0;
    font-size: 20px;
    text-align: left;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 40px 15px 0;
  }

  .infoCard {
    padding: 0 20px 30px;
  }

  .title {
    font-size: 28px;
  }

  .description {
    font-size: 18px;
  }
}
