.mainContainer {
  height: 100%;
  max-height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.icon {
  height: 1.5rem;
  width: auto;
}

.rating {
  line-height: 0.75;
  font-size: 1.4rem;
  font-weight: 400;
  color: #838991;
  margin-inline-start: 0.3rem;
}

.reviews {
  line-height: 0.75;
  font-size: 1.4rem;
  font-weight: 400;
  color: #b9c3ce;
  margin-inline-start: 0.5rem;
  color: var(--color-main-green);
  white-space: nowrap;
}
