.mainContainer {
  width: 100%;
}

.topContainer {
  display: flex;
  flex-direction: row;
  padding-inline-end: 2%;
}

.nameContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.headerContainer {
  display: flex;
  flex-direction: row;
}

.headerText {
  font-weight: 600;
  font-size: 2.2rem;
  color: #596475;
  margin-bottom: 1rem;
}

.listContainer {
  display: flex;
  flex-direction: column;
}

.itemContainer,
.itemContainerHover {
  display: flex;
  flex-direction: row;
  padding: 1.4rem;
  margin-bottom: 1.6rem;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
  border: 1px solid transparent;

  cursor: pointer;
}
.itemContainer:hover {
  box-shadow: 0px 5px 25px rgba(129, 188, 69, 0.3);
}

.itemContainerHover {
  box-shadow: 0px 5px 25px rgba(129, 188, 69, 0.3);
}

.logo {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  object-fit: fill;
}

.logoContainer {
  width: 17rem;
  height: 11.2rem;
  min-width: 1.7rem;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  margin-inline-start: 1.5rem;
  width: 100%;
  justify-content: space-between;
}
.restName {
  font-weight: 600;
  font-size: 1.8rem;
  color: #586576;
  margin-bottom: 0.5rem;
}
.restDescription {
  line-height: 1.3rem;
  max-height: 2.6rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--color-secondary-grey);
  overflow: hidden;
  text-overflow: ellipsis;
  padding-inline-end: 1rem;
  margin-bottom: 0.5rem;
}
.restAddress {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-secondary-grey);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.row,
.categoryContainer,
.categoriesContainer,
.distanceRow {
  display: flex;
  flex-direction: row;
}

.distanceRow {
  gap: 0.3rem;
  align-items: center;
  margin-bottom: 0.5rem;
}

.distance {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 17px;
  color: #596475;
}

.categoriesContainer {
  width: 100%;
  flex-wrap: wrap;
  max-height: 1.5rem;
  overflow: hidden;
  margin-bottom: 0.2rem;
}

.restCategory {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-main-green);
  white-space: nowrap;
}

.categoryDot {
  font-size: 1rem;
  color: var(--color-main-green);
  margin: 0rem 0.3rem;
  align-self: center;
}

.categoryContainer:first-child p:first-child {
  display: none;
}

.tagsContainer {
  width: 100%;
  height: max-content;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  align-self: flex-end;
}

.greyIconTags {
  margin-top: 0.4rem;
  width: 100%;
  justify-content: flex-start;
}

.rating {
  margin-bottom: 0.4rem;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .greyIconTags {
    margin-top: 0.2rem;
  }
}
