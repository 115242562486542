.mainContainer {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
}

.imageContainer {
  height: 12rem;
}

.noCursor {
  cursor: inherit !important;
}
.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  cursor: pointer;
}

.imageContainer,
.image {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.contentContainer {
  height: 12rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.6rem 2rem;
  cursor: pointer;
}

.companyTitle {
  width: 100%;
  font-size: 2rem;
  line-height: 2rem;
  height: 2rem;
  font-style: normal;
  font-weight: 600;
  color: #586576;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
  text-align: start;
}

.companyDescription {
  font-size: 1.3rem;
  line-height: 1.5rem;
  height: 3rem;
  font-style: normal;
  font-weight: 400;
  color: #838990;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}
.expendedDescription {
  font-size: 1.3rem;
  line-height: 1.5rem;
  height: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  color: #838990;
  text-align: start;
}

.tagContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: 0.5rem;
  flex-wrap: wrap;
  height: 1.5rem;
  overflow: hidden;
}

.tag {
  height: 2rem;
  font-weight: 400;
  font-size: 1.4rem;
  margin-inline-end: 0.5rem;
  white-space: nowrap;
  color: var(--color-main-green);
}
