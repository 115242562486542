.mapButtons {
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 4rem;
  margin-bottom: 1.5rem;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: space-between;
}

.button {
  position: absolute;
  margin: 1.5rem 20% !important;
  bottom: 0;
}
.locationButton {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 1.7rem;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.25);
}

.locationContainer {
  position: absolute;
}
