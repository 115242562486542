main * {
	font-size: 1.8rem;
	line-height: 2rem !important;
}

main section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	text-align: center;
	padding: 3rem;
}

main h2 {
	font-size: 2.2rem;
	font-weight: 800;
}
main {
	/* max-width: 50% !important; */
	margin: 0 10% 0 10%;
	display: flex !important;
	flex-direction: column !important;
	justify-content: center !important;
	padding: 5rem;
}
main ul {
	list-style-type: none;
}