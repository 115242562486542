.mainContainer {
  background-color: transparent !important;
  align-self: center;
  border: none;
  background-color: #d9ebc7;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 3.2rem 5.4rem;
  background-color: #d9ebc7;
  padding: 3.2rem 15%;
}

.mainTitle {
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
  padding-bottom: 2.4rem;
}

.subTitle {
  font-weight: 400;
  font-size: 2.4rem;
  color: #596475;
  margin-bottom: 2.4rem;
  text-align: center;
}

.benefitItemContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border-radius: 1.5rem;
  padding: 1.6rem;
  gap: 1.6rem;
  margin-bottom: 2.4rem;
  cursor: pointer;
  box-shadow: 0px 10px 25px #838991b6;
}

.benefitItemIcon {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 5px;
}

.benefitItemTitle {
  font-weight: 600;
  font-size: 1.8rem;
  color: #586576;
}

.benefitItemDescription {
  font-weight: 400;
  font-size: 1.4rem;
  color: #586576;
}

.benefitItemMark {
  height: 4.7rem;
  min-width: 6.8rem;
  margin-top: -4.5rem;
  background-image: url("../../assets/images/benefit_mark.svg");
  text-align: center;
  padding-top: 1.2rem;

  padding-left: 0.8rem;
  font-weight: 400;
  font-size: 1.5rem;
  color: white;
}

.exitButton,
.exitButtonIL {
  position: absolute;
  margin-inline: 1.5rem;
  margin-top: -6.5rem;
  cursor: pointer;
}

.exitButton {
  left: 0;
}

.exitButtonIL {
  right: 0;
}

.buttonJoinVF {
  width: 100%;
  padding: 1.2rem;
  margin-bottom: 2rem;
}

.button {
  width: 100%;
  padding: 1.2rem;
  margin-bottom: 1rem;
}

@media only screen and (max-width: 48em) {
  .mainContainer {
    margin: 0rem 2rem;
  }

  .contentContainer {
    padding: 3rem 2rem 1rem 2rem;
  }
}
