.mainContainer {
  width: 100%;
  padding-inline: var(--common-padding-inline);
  margin-bottom: 5rem;
}

.title {
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
  padding-top: 3rem;
  padding-bottom: 0.4rem;
}

.description {
  font-weight: 800;
  font-size: 2rem;
  color: #596475;
  padding-top: 0rem;
  padding-bottom: 0.4rem;
}


.markdownContainer p {
  font-weight: 100;
  font-size: 2rem;
  color: #596475;
  padding-bottom: 0.4rem;
}

.markdownContainer em {
  font-style: normal;
  font-weight: 600;
}

.gridContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.markdownContainer img {
  max-width: 50%;
  max-height: 50vh;
  height: auto;
  display: block;
  margin: auto;
}

.markdownContainer strong {
  font-weight: normal;
}

.socialsContainer {
  max-width: 68%;
}

.content {
  width: 100%;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--color-text);
  line-height: 1.4;
  padding: 0rem 1rem;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    padding-inline: var(--mobile-padding-inline);
  }

  .wrongInfoContainer {
    display: flex;
  }
}
