.certificationButton {
  height: 100%;
  display: flex;
  flex-direction: column;
  color: rgba(92, 138, 45, 1);
  font: 400 28px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.certificationButton:hover {
  display: flex;
  flex-direction: column;
  color: white;
  font: 400 28px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}
.buttonWrapper {
  min-height: 60px;
  border-radius: 31.5px;
  border: 1px solid var(--light-green, #effce3);
  background: var(--white, #fff);
  display: flex;
  gap: 10px;
  padding: 10px 65px;
  cursor: pointer;
  box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.1);
}
.buttonWrapper:hover {
  border-radius: 31.5px;
  border: 1px solid white;
  background: rgba(92, 138, 45, 1);
  display: flex;
  gap: 10px;
  /* padding: 13px 65px; */
  cursor: pointer;
}
.buttonWrapper:hover > .certificationIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 38px;
  filter: invert(1) brightness(2);
}

.certificationIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 38px;
}

.buttonText {
  flex-grow: 1;
  align-self: center;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
