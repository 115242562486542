.photo {
  display: flex;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.exitButton {
  position: absolute;
  padding: 1rem;
  top: 0;
  right: 0;
  cursor: pointer;
}
