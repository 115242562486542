.modalClass {
  padding: 0rem 10rem;
}

.backdrop {
  opacity: 0.7 !important;
  background-color: black;
}

.modalContainer {
  background-color: transparent !important;
  align-self: center;
  border: none;
}

.exitButton,
.exitButtonIL {
  height: 2rem;
  width: 2rem;
  margin-bottom: 2rem;
  margin-inline: 2rem;
  align-self: flex-end;
  cursor: pointer;
}

.exitButtonIL {
  align-self: flex-start;
}

.carousel {
  width: 100%;
  direction: initial;
}

.mainContainer {
  width: 100%;
  height: 55rem;
  max-height: 55rem;
  display: flex;
  align-self: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0rem 1rem;
}

.image {
  object-fit: contain;
  max-height: 50rem; /* change this to whatever you want */
  max-width: min-content;
  border-radius: 20px;
  margin-bottom: 4rem;
  background-color: white;
}

.directions {
  direction: rtl;
}

.carousel div {
  align-self: center;
  display: flex;
  justify-content: center;
}

.previousButton {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-inline-end: 5rem;
  z-index: 9;
}

.nextButton {
  position: absolute;
  bottom: 0;
  right: 0;

  z-index: 9;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .modalContainer {
    height: 80vh;
  }

  .modalClass {
    width: 100%;
    max-width: 100%;
    padding: 0rem 2rem;
  }

  .backdrop {
    opacity: 0.9 !important;
    background-color: black;
  }

  .mainContainer {
    width: 100%;
    max-height: 70vh;
    height: 70vh;
  }

  .image {
    max-height: 65vh;
  }

  .previousButton {
    display: none;
  }

  .nextButton {
    display: none;
  }
}
