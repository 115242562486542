.mainContainer {
  width: 100%;
  align-items: center;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.8rem;
}

.about {
  font-weight: 400;
  font-size: 1.6rem;
  color: #596475;
  margin-bottom: 1.9rem;
}

.singleDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon {
  max-width: 1.3rem;
  height: 1.3rem;
}

.iconSmall {
  max-width: 1.3rem;
  height: 1.3rem;
}

.detailsName {
  font-weight: 400;
  font-size: 1.8rem;
  color: #596475;
  padding-inline-start: 0.6rem;
  margin-inline-end: 1.2rem;
}

.detailsNameSmall {
  font-weight: 400;
  font-size: 1.5rem;
  color: #596475;
  padding-inline-start: 0.6rem;
  margin-inline-end: 0.8rem;
}

.dot {
  text-align: center;
  font-size: 1.8rem;
  padding-inline-end: 1.2rem;
}

.divider {
  width: 100%;
  height: 0.05rem;
  background-color: var(--color-main-green);
}
