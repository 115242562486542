.mainContainer {
  height: 65vh;
}

.containerWithMap {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.showMapButtonContainer {
  right: 0;
  left: 0;
  position: absolute;
  z-index: 1;
}

.showMapButtonVisible,
.showMapButtonHidden {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  width: fit-content;
  margin: 1rem auto;
  padding: 0.5rem 1.5rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3.5px);
  cursor: pointer;
}

.showMapButtonHidden {
  opacity: 0;
  transition: opacity 0.5s linear !important;
}

.showMapButtonVisible {
  opacity: 1;
  transition: opacity 0.5s linear !important;
}

.showMapText {
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  color: #596475;
}

.containerNoMap {
  display: flex;
  flex-direction: column;
  margin: 1rem 1.5rem 2.4rem 1.5rem;
}

.topBar,
.visible,
.hidden {
  display: flex;
  width: 100%;
  height: 10rem;
  background-color: firebrick;
  animation-duration: 1s;
  animation-name: slidein;
}

.map {
  display: flex;
  width: 100%;
  flex: 1;
  margin-bottom: 5rem;
}

.visible {
  height: 10rem;
  transition: height 0.5s linear;
}

.hidden {
  height: 0rem;
  transition: height 0.5s linear;
}
