.mainContainer {
  width: 100%;
  align-self: center;
  z-index: 5;
  position: relative;
}

.inputContainer {
  width: 100%;
  padding: 0rem 1rem;
  position: absolute;
}

.input {
  width: 100%;
  padding: 1rem 1.75rem;
  font-weight: 400;
  font-size: 1.8rem;
  color: black;
  border-radius: 20px;
  background-color: white;
}

.resultsContainer {
  margin-top: 1.5rem;
  border-radius: 20px;
  background-color: white;
}

.result,
.resultActive {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
}

.resultActive {
  background-color: white;
}

.resultIcon {
}

.resultName {
  color: #596475;
  font-weight: 400;
  font-size: 1.8rem;
}
