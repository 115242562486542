.mainContainer {
  width: 100%;
  margin: auto;
  margin-bottom: 4rem;
}

.headerContainer {
  display: flex;
  margin-inline-start: 1.5rem;
  flex-direction: row;
  align-items: flex-end;
  line-height: 0.7;
}

.headerText {
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
  line-height: 0.6;
}

.headerButton {
  margin-inline-start: 0.6rem;
  font-weight: 500;
  font-size: 1.4rem;
  padding-bottom: 0.3rem;
  color: var(--color-main-green);
  cursor: pointer;
}

.arrowButtonLeft,
.arrowButtonRight {
  position: absolute;
  outline: 0;
  transition: all 0.5s;
  border-radius: 3.5rem;
  border: 0;
  background-color: var(--color-main-green);
  width: 4rem;
  height: 4rem;
  opacity: 0.7;
  cursor: pointer;
  display: flex;
}
.arrowButtonRight {
  right: 0;
}

.arrowButtonLeft {
  left: 0;
}

.arrowButtonLeft:hover,
.arrowButtonRight:hover {
  opacity: 1;
}

.arrowIcon {
  margin: auto;
}

.carouselContainerEn {
  direction: ltr;
}

.carouselContainerHe {
  direction: ltr;
  transform: scaleX(-1);
}

.entityContainerEn,
.entityContainerHe {
  margin: 2rem auto 3rem auto;
  width: 90%;
  cursor: pointer;
}

.entityContainerHe:first-child {
  margin-inline-start: auto;
}

.entityContainerHe {
  transform: scaleX(-1);
  direction: rtl;
  cursor: pointer;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .webContainer {
    padding-inline: var(--mobile-padding-inline);
  }

  .mobileContainer {
    display: flex;
    width: 100%;
    overflow: scroll;
    padding-inline: var(--mobile-padding-inline);
  }

  .headerContainer {
    padding-inline: var(--mobile-padding-inline);
    margin-inline-start: 0.5rem;
  }

  .entityContainerEn {
    margin: 2rem 1rem 3rem 1rem;
    min-width: 80%;
  }
}
