.mainContainer {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  white-space: wrap;
  height: 2.3rem;
  white-space: nowrap;
}

.icon {
  padding: 0.2rem 0rem;
  height: 90%;
  width: auto;
  object-fit: fill;
  color: #b9c3ce;
  fill: #b9c3ce;
}

.row:last-child .dot {
  display: none;
}

.dot {
  font-size: 1rem;
  margin: 0rem 0.5rem 0rem 0.5rem;
  align-self: center;
  color: var(--color-secondary-grey);
}

.distance {
  font-weight: 500;
  font-size: 1.4rem;
  color: #596475;
  white-space: nowrap;
}

.name {
  font-weight: 400;
  font-size: 1.4rem;
  color: #596475;
  white-space: nowrap;
  padding-inline-start: 0.5rem;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
}
