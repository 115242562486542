.mainContainer {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; /* Vertically center align the child elements */
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.categoriesContainer,
.row,
.ratingRow {
  display: flex;
  flex-direction: row;
}

.ratingRow {
  margin-inline-start: 0.2rem;
  margin-bottom: 2rem;
}

.categoriesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.6rem;
}

.shareRow {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  gap: 1rem;
  margin-bottom: 1.2rem;
}

.printButton {
  height: 4rem;
  width: 4rem;
}

.shareButton {
  padding: 0rem 1rem !important;
  height: 3rem !important;
  margin: auto 0rem;
}

.thumbnail {
  max-height: 10rem;
  max-width: 10rem;
  object-fit: cover;

  border-radius: 2px;
  margin-right: 20px;
}
.infoContainer {
  display: flex;
  flex-direction: column; /* Stack the entity name and categories vertically */
  align-items: flex-start;
  margin-right: 20px;
  justify-content: flex-start;
}

.smile {
  height: 2rem;
  margin-bottom: 0.3rem;
  margin-inline-end: 0.5rem;
}
.title {
  font-weight: 700;
  font-size: 2.6rem;
  color: var(--color-text);
  line-height: 0.7;
  padding-top: 0.7rem;
  margin-bottom: 2rem;
}

.dot,
.categoryDot {
  margin: 0rem 0.8rem;
  font-size: 1rem;
  line-height: 1.8;
  color: #596475;
}
.rating {
  font-weight: 400;
  font-size: 1.8rem;
  color: var(--color-text);
  white-space: nowrap;
}

.space {
  font-weight: 400;
  font-size: 1.8rem;
  color: var(--color-text);
  white-space: nowrap;
  margin-inline: 1rem;
}

.reviews {
  font-weight: 400;
  font-size: 1.8rem;
  color: var(--color-main-green);
  white-space: nowrap;
}
.tag {
  font-weight: 400;
  font-size: 1.8rem;
  white-space: nowrap;
  color: var(--color-main-green);
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.tag:hover {
  color: #69a42e;
}

.imagesContainer {
  direction: ltr;
}

.cover {
  width: 100%;
  height: 30rem;
  border-radius: 10px;
  object-fit: cover;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .cover {
    width: 100%;
    height: 20rem;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
  }

  .thumbnail {
    max-height: 5rem;
    max-width: 5rem;
    object-fit: cover;
    border-radius: 2px;
    margin-right: 20px;
  }

  .ratingRow {
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .categoryDot {
    display: none;
  }

}
