.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.headerContainer {
  display: flex;
  flex-direction: row;
}

.headerText {
  font-weight: 600;
  font-size: 2.2rem;
  color: #596475;
  margin-bottom: 1rem;
}

.searchTitle {
  font-weight: 600;
  font-size: 2.2rem;
  color: #596475;
  margin-bottom: 1rem;
}

.listContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.seeAllBtn {
  width: fit-content;
  padding: 1.4rem 2.8rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: #596475;
  box-shadow: 0px 6px 20px rgba(129, 188, 69, 0.2);
  border-radius: 10px;
  cursor: pointer;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    padding-inline: var(--mobile-padding-inline);
    min-height: 45rem;
  }
}
