.mainContainer {
  background-color: transparent !important;
  align-self: center;
  border: none;
}

.contentContainer {
  margin: 5rem 3rem 10rem 3rem;
  background-color: white;
  border-radius: 20px;
  padding: 2rem 5rem;
}

.title {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--color-main-green);
}

.exitButton,
.exitButtonIL {
  position: absolute;
  margin-top: -5rem;
  margin-inline: 4rem;
  cursor: pointer;
}

.exitButton {
  right: 0;
}

.exitButtonIL {
  left: 0;
}

@media only screen and (max-width: 48em) {
  .contentContainer {
    padding: 2rem 2.5rem;
  }
}
