.mainContainer {
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: row;
}

.headerText {
  margin-inline-start: 1.5rem;
  margin-bottom: 2rem;
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
}

.gridContainer {
  width: 97%;
  margin: 0rem auto;
  flex-wrap: wrap;
  justify-content: center;
  gap: 3rem;
  display: flex;

  /* grid-template-columns: 1fr 1fr 1fr; */
}

.gridContainer:first-child,
.gridContainer:nth-child(2) {
  justify-content: center;
}

.entityContainer {
  width: calc(33.3333% - 4rem); /* Adjust the subtraction value based on the total gap size */
  flex: 0 0 auto;
  box-sizing: border-box; /* Ensure padding and borders are included in the width */
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .gridContainer {
    padding-bottom: 4rem;
  }

  .entityContainer {
    flex-basis: 100%; /* Each item will take the full width of the container */
    max-width: 100%; /* Ensuring the flex item doesn't grow beyond 100% width */
  }
}