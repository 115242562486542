.mainContainer {
  width: 100%;
  padding-inline: var(--common-padding-inline);
  margin-bottom: 5rem;
}

.gridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 0fr 1fr;
  grid-template-areas:
    "ingredients details"
    "ingredients instructions"
    "ingredients reviews";
  column-gap: 5rem;
  margin-bottom: 2rem;
}

.ingredientsContainer {
  grid-area: ingredients;
  margin-top: -10rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.detailsContainer {
  grid-area: details;
}

.instructionsContainer {
  grid-area: instructions;
}

.wrongInfoContainer {
  grid-area: wrongInfo;
  display: none;
}

.reviewsContainer {
  grid-area: reviews;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    padding-inline: var(--mobile-padding-inline);
  }
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "details "
      "ingredients"
      "instructions"
      "wrongInfo"
      "reviews";
    gap: 2rem;
  }
  .wrongInfoContainer {
    display: flex;
  }

  .ingredientsContainer {
    grid-area: ingredients;
    margin-top: 0rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}
