/* The container <div> - needed to position the dropdown content */
.dropdown {
  width: 100%;
  position: relative;
  display: inline-block;
  flex-wrap: wrap;
}

/* Dropdown Content (Hidden by Default) */
.dropdownContent,
.dropdownContentOpen {
  display: block;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 1rem 0rem;
  background-color: white;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.dropdownContentOpen {
  display: block;
  z-index: 99;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}

/* Links inside the dropdown */
.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.headerContainer {
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 2rem 2rem 1rem;
  background-color: white;
  display: none;
}

.title {
  font-weight: 700;
  font-size: 1.8rem;
  color: #596475;
  padding: 0rem 1rem;
  line-height: 1.2;
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 62em) {
  .dropdownContent,
  .dropdownContentOpen {
    margin-top: -2rem;
  }

  .headerContainer {
    display: flex;
  }
}
