body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  /* 10px / 16px = 0.625 = 62.5% */
  font-size: 62.5%;
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}

/* Large devices (laptops/desktops, , 1200px and down) */
@media only screen and (max-width: 112.5em) {
  html {
    font-size: 62.5%;
  }
}

/* Medium devices (landscape tablets, 992px and down) */
@media only screen and (max-width: 62em) {
  html {
    font-size: 58%;
  }
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 48em) {
  html {
    font-size: 56.2%;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 38em) {
  html {
    font-size: 56.2%;
  }
}
