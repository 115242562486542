.mainContainer {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
  cursor: pointer;
}

.imageContainer {
  height: 12rem;
  cursor: pointer;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.imageContainer,
.image {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  object-fit: cover;
}

.tags {
  font-weight: 400;
  font-size: 1.6rem;
  color: #586576;
  margin-top: auto;
}

.contentContainer {
  height: 14rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.6rem 2rem;
  align-items: flex-start;
  cursor: pointer;
}

.newsTitle {
  font-weight: 600;
  font-size: 1.8rem;
  color: #586576;
  text-align: start;
  max-height: 4rem;
  line-height: 2rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.newsDescription {
  font-size: 1.3rem;
  line-height: 1.5rem;
  height: 3rem;
  font-style: normal;
  font-weight: 400;
  color: #838990;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}

.tagsContainer {
  margin-top: auto;
  height: 2rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}

.tag {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 0.5rem;
}

.tagImg {
  height: 1.4rem;
  width: 1.4rem;
}

.tagName {
  font-size: 1.4rem;
  color: #838991;
}
