.mainContainer {
  width: 100%;
  margin-bottom: 2rem;
}

.row,
.rowSpaced {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowSpaced {
  justify-content: space-between;
  margin-top: 1.5rem;
}

.mainHeader {
  font-weight: 600;
  font-size: 2rem;
  color: var(--color-text);
  margin-inline-end: 0.6rem;
  text-align: center;
}

.subHeader {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-text);
  margin-inline-end: 2rem;
}

.giftIcon {
  height: 1.5rem;
  width: 1.5rem;
  margin-inline-end: 0.8rem;

  margin-bottom: 0.5rem;
}

.informationIcon {
  height: 0.8rem;
  width: 0.8rem;
  margin-inline-end: 0.4rem;
}

.joinButton {
  display: flex;
  flex-direction: row;
  padding: 0.2rem 0.8rem 0.2rem 1.4rem;
  box-shadow: 0px 6px 20px rgba(129, 188, 69, 0.3);
  border-radius: 10px;
  margin: 0rem 0.5rem;
  align-self: flex-end;
  cursor: pointer;
}

.joinButton:hover {
  box-shadow: 0px 6px 20px rgba(129, 188, 69, 0.5);
}
.joinText {
  font-weight: 500;
  font-size: 1.4rem;
  color: var(--color-main-green);
  padding-inline-end: 0.5rem;
  line-height: 1;
  align-self: center;
  padding-top: 0.8rem;
}
.joinIcon {
  height: 3rem;
  width: auto;
}

.benefitContainer {
  width: 60%;
  display: flex;
  flex-direction: row;
  margin-top: 1.3rem;
  cursor: pointer !important;
}

/* .benefitContainer:hover {
  background: #f3f4fb;
  border-radius: 10px;
} */

.benefitContainer p {
  cursor: pointer !important;
}
.benefitImage {
  height: 6rem;
  width: 6rem;

  border-radius: 10px;
  margin-inline-end: 1rem;
}
.benefitContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.9rem 0rem;
}
.benefitName {
  font-weight: 400;
  font-size: 1.8rem;
  color: #596475;
}
.delivery {
  font-weight: 400;
  font-size: 1.4rem;
  color: #838990;
}
.information {
  font-weight: 400;
  font-size: 1.4rem;
  text-decoration-line: underline;
  color: #81bc45;
}
.divider {
  width: 100%;
  height: 0.05rem;
  background-color: var(--color-main-green);
}

.menuPlaceholderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
}

.placeholderImage {
  margin-top: 3rem;
  margin-bottom: -2.5rem;
}

.placeholderText {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  color: #596475;
  margin-bottom: 1rem;
  text-align: center;
  padding: 0rem 3rem;
  line-height: 1.1;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .rowSpaced {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .mainHeader {
    font-size: 2.2rem;
  }
}
