.inputContainer {
  width: 100%;
  margin-bottom: 0.8rem;
}

.input,
.inputError {
  width: 100%;
  padding: 1rem;
  line-height: 1.5;
  background-color: #f3f4fb;
  border-radius: 10px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #596475;
  margin-bottom: 0.4rem;
  border: 1px solid red;
  border-bottom-width: 1px;
  border: none;
  outline: none;
  caret-color: #596475;
  resize: none;
}

.input::-webkit-scrollbar {
  display: none;
}

.input::placeholder {
  color: #b9c3ce;
}

.input:focus {
  border: 1px solid #b9c3ce;
}

.inputError {
  background-color: #ffdfe6;
}

.errorMessage {
  color: #ff4a4a;
  font-weight: 400;
  font-size: 1.4rem;
  white-space: nowrap;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.minChars {
  flex: 1;
  text-align: end;
  font-weight: 400;
  font-size: 1.2rem;
  color: #596475;
  white-space: nowrap;
}
