.mainContainer {
  background-color: transparent !important;
  align-self: center;
  border: none;
}

.contentContainer {
  background-color: white;
  align-self: center;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 0rem 4rem 2rem 4rem;
  justify-content: space-between;
}

.cowImg {
  height: 15rem;
  width: auto;
  margin-top: -3rem;
  margin-bottom: 2.4rem;
}

.title {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 24px;
  color: #596475;
  margin-bottom: 0.8rem;
}

.subTitle {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 17px;
  color: #838991;
  margin-bottom: 1.6rem;
  text-align: center;
}

.caseNumber {
  font-weight: 600;
  font-size: 1.4rem;
  color: #596475;
  margin-bottom: 1.6rem;
}

.button {
  margin-top: 0.3rem;
  width: 90%;
}
