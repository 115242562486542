.mainContainer {
  background-color: transparent !important;
  align-self: center;
  border: none;
}

.contentContainer {
  position: relative;
  margin: 5rem 3rem 10rem 3rem;
  background-color: white;
  border-radius: 20px;
  padding: 2rem 5rem;
}

.title {
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--color-main-green);
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 2.5rem;
  justify-content: flex-end;
}

.rowSecondPage {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5rem;
  justify-content: space-between;
}

.pageNumber {
  font-weight: 400;
  font-size: 1.4rem;
  color: #838991;
  margin-bottom: 1rem;
}

.previewPhoto {
  height: 10rem;
  width: 10rem;
  object-fit: cover;
}

.addRatingTitle {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 1.4rem;
  color: #596475;
  margin-bottom: 0.8rem;
}

.rateTitle {
  font-size: 14px;
  line-height: 1.4rem;
  color: #838991;
  padding-bottom: 0.8rem;
  width: 100%;
  text-align: center;
}

.placeholder {
  font-weight: 400;
  font-size: 1.2rem;
  color: #838991;
}

.ratingContainer,
.ratingContainerError {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0.3rem;
  padding-bottom: 1rem;
}

.ratingContainerError {
  animation: shake 0.15s linear;
  animation-iteration-count: 4;
}

.loaderContainer {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.progress {
  width: 40%;
  height: 2.7rem;
  border-radius: 20px;
  background: linear-gradient(var(--color-main-green) 0 0) 0/0% no-repeat var(--color-light-green);
  animation: p2 1.5s infinite steps(20);
  margin-bottom: 2.2rem;
  text-align: center;
  padding-top: 0.7rem;
  font-size: 1.5rem;
  font-weight: 500;
  color: black;
}

@keyframes p2 {
  100% {
    background-size: 110%;
  }
}

@keyframes shake {
  0% {
    /* margin-right: 0;
    margin-left: -0.5rem; */
    transform: scaleX(1) scaleY(1);
  }
  100% {
    /* margin-right: -0.5rem;
    margin-left: 0; */
    transform: scaleX(1.05) scaleY(1.05);
  }
}

.ratingFace,
.ratingFaceSelected,
.ratingFaceError {
  display: block;
  height: 3rem;
  width: 3rem;
  filter: grayscale(100) brightness(0.8);
}

.ratingFaceSelected {
  filter: none;
}

.ratingFaceError {
  filter: brightness(0.85) hue-rotate(260deg);
}

.hashTagsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 0.5rem;
  row-gap: 0.5rem;
}

.exitButton,
.exitButtonIL {
  position: absolute;
  margin-top: -7rem;
  margin-inline: 2rem;
  cursor: pointer;
}

.exitButton {
  right: 0;
}

.exitButtonIL {
  left: 0;
}

.fileDragArea {
  height: 20rem !important;
}

.addPhotosTitle {
  font-weight: 600;
  font-size: 1.8rem;
  color: #596475;
  width: 100%;
}

.columTitleContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
  margin-bottom: 1.5rem;
}

.addPhotosSubtitle {
  padding-top: 0.4rem;
  white-space: pre-line;
  font-weight: 400;
  font-size: 1.4rem;
  color: #838991;
}

.addMoreImageDragContainer {
  width: min-content !important;
}

.addMoreImagesContainer {
  flex-direction: row !important;
  border: solid 1px black !important;
  width: fit-content !important;
  padding: 0.5rem 1rem;
  border-radius: 20px !important;
}

.addPhotosPlaceHolder {
  width: 100%;
  text-align: start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1.2rem;
  padding-top: 0.6rem;
  color: #838991;
}

.addTagsTitle {
  font-weight: 600;
  font-size: 1.4rem;
  color: #596475;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
}

.backButton {
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1rem 0rem 1rem 2rem;
  color: var(--color-main-green);
}

.error {
  font-weight: 600;
  font-size: 1.4rem;
  color: red;
  text-align: center;
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 48em) {
  .contentContainer {
    border-radius: 14px;
    padding: 1.6rem 1.6rem;
  }

  .fileDragArea {
    height: 12rem !important;
  }

  .addPhotosPlaceHolder {
    flex-direction: column-reverse;
    justify-content: space-between;
    gap: 0.2rem;
  }

  .addPhotosPlaceHolder p:last-child {
    flex: 1;
    text-align: end;
  }

  .backButton {
    font-weight: 500;
    font-size: 1.5rem;
    padding: 1rem 0rem 1rem 2rem;
    color: var(--color-main-green);
  }

  .error {
    font-weight: 600;
    font-size: 1rem;
    color: red;
    text-align: center;
    padding: 0rem 1rem;
  }
}
