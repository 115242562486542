.mainContainer {
  width: 100%;
  background-color: transparent !important;
}

.backdrop {
  opacity: 0.8 !important;
  background-color: black;
}

.exitButton,
.exitButtonIL {
  position: absolute;
  margin-top: -4rem;
  margin-inline: 2rem;
  cursor: pointer;
}

.exitButton {
  right: 0;
}

.exitButtonIL {
  left: 0;
}
