.mainContainer {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 5rem;
}

.topDivider {
  width: 68%;
  height: 0.05rem;
  background-color: var(--color-main-green);
  margin-bottom: 2rem;
}

.headerContainer {
  margin-bottom: 2rem;
}

.mainHeader {
  font-weight: 600;
  font-size: 2rem;
  color: var(--color-text);
  margin-inline-end: 0.6rem;
  text-align: center;
}

.reviewIcon {
  height: 1.5rem;
  width: 1.5rem;
  margin-inline-end: 0.8rem;
}

.reviewerImage {
  height: 3.2rem;
  width: 3.2rem;
}

.smile {
  margin-inline-start: 0.2rem;
  margin-inline-end: 0.5rem;
}

.rating {
  font-weight: 400;
  font-size: 1.8rem;
  color: var(--color-text);
  white-space: nowrap;
}

.addReviewContainer {
  display: flex;
  flex-direction: row;
  width: 40%;
  margin-bottom: 2.3rem;
  gap: 1rem;
}

.searchBar {
  align-self: flex-end !important;
  width: 20% !important;
  padding: 0.5rem 1rem !important;
  background-color: #f3f4fb !important;
  margin-inline-end: 2rem;
}

.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2.3rem;
}

.filterButtonContainer {
  margin: 0.2rem;
  padding: 0.2rem 1rem;
}

.filtersTitle {
  font-weight: 600;
  font-size: 1.5rem;
  margin-inline-end: 1rem;
}

.reviewsCountLine {
  font-size: 1.4rem;
  color: #838991;
  margin-bottom: 2.5rem;
}

.reviewsContainer {
  width: 100%;
  column-gap: 5em;
  column-count: 2;
}

.reviewsContainerNoResults {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  margin-top: 4rem;
}

.noResultsTitle {
  width: 100%;
  font-weight: 500;
  font-size: 1.8rem;
  color: #596475;
  text-align: center;
  line-height: 1;
  margin-top: 2rem;
}

.row,
.headerContainer,
.bottomRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentContainer {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.filterButtonsContainer {
  display: flex;
  flex-direction: row;
}

.emptyReviewsContainer {
  width: 68%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.emptyReviewsImage {
  width: min-content;
  margin-bottom: 3rem;
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    margin-top: 2rem;
  }

  .searchBar {
    align-self: center !important;
    width: 100% !important;
    padding: 0.5rem 1rem !important;
    background-color: #f3f4fb !important;
    margin-inline-start: 2rem;
    margin-inline-end: 2rem;
    margin-bottom: 2rem;
  }

  .reviewsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3rem;
    margin-top: 1rem;
  }

  .filtersContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .filterButtonsContainer {
    margin-top: 1rem;
  }

  .emptyReviewsContainer {
    width: 100%;
  }

  .topDivider {
    width: 100%;
  }

  .reviewsContainerNoResults {
    width: 100%;
  }
}
