.mainContainer {
  position: relative;
}

.wrapper {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.resultsContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-top: 1rem;
  border-radius: 30px;
  z-index: 20;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
}
