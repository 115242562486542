.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.title {
  overflow: hidden;
  font-weight: 600;
  font-size: 20px;
  color: #596475;
  margin-bottom: 1.7rem;
}

.slider {
  padding: 2rem 0rem;
  margin-bottom: 5rem;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;
  direction: ltr;
}

/* total number of slides * individual slide width (250px) */
.slideTrack {
  display: flex;
  width: calc(15rem * 48);
  animation: scroll 40s linear infinite;
  animation-direction: alternate;
  justify-content: space-between;
  gap: 1rem;
}

.slide {
  height: 4rem;
  width: auto;
  display: flex;
  align-items: center;
  perspective: 100px;
}

.img {
  height: 100%;
  width: 100%;
}

.img:hover {
  transform: translateZ(20px);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-15rem * 24));
  }
}

/* Shadows */

.slider::before,
.slider::after {
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(225, 225, 225, 0) 100%
  );
  content: "";
  height: 100%;
  position: absolute;
  width: 2%;
  z-index: 2;
}

.slider::before {
  left: 0;
  top: 0;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
