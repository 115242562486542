.mainContainer {
  display: flex;
  align-items: center;
  height: 44rem;
  background-image: url("../../../assets/images/about_us/about_us_background_web.svg");
  background-repeat: no-repeat;
  background-size: cover;
}
.contentContainer {
  width: 50%;
  padding-top: 4%;
  padding-inline-start: 20%;
}
.title {
  font-weight: 700;
  font-size: 6rem;
  color: #ffffff;
  margin-bottom: 1.5rem;
  white-space: break-spaces;
}
.subtitle {
  font-weight: 600;
  font-size: 2.4rem;
  color: #ffffff;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    display: flex;
    align-items: flex-start;
    height: 60rem;
    background-image: url("../../../assets/images/about_us/about_us_background_mobile.svg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .contentContainer {
    width: 100%;
    padding-top: 5rem;
    padding-inline-start: 2rem;
    padding: 5rem 2rem;
  }
  .title {
    font-weight: 700;
    font-size: 6rem;
    color: #ffffff;
    margin-bottom: 1.5rem;
  }
  .subtitle {
    font-weight: 600;
    font-size: 2.4rem;
    color: #ffffff;
  }
}
