.main {
  margin: 20px;
  display: flex;
  height: 30rem;
  position: relative;
  border-radius: 20px;
}

.center {
  position: relative;
  height: 98%;
  width: 99.2%;
  margin: auto;
  border-radius: 15px;
  background-color: white;
}

.top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 90%;
  border-radius: 20px;
  background-color: blue;
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 90%;
  border-radius: 20px;
  background-color: teal;
}

.left {
  position: absolute;
  left: 0;
  width: 90%;
  height: 100%;
  border-radius: 20px;
  background-color: yellow;
}

.right {
  position: absolute;
  right: 0;
  width: 90%;
  height: 100%;
  border-radius: 20px;
  background-color: red;
}
