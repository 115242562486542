.mainContainer {
  min-width: min-content;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 0rem 1rem 1rem;
  display: none;
}
.trademarkContainer {
  display: none;
}

.itemsContainer {
  width: max-content;
  display: flex;
  flex-direction: column;
}

.menuItem,
.menuItemAboutUs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 2rem;
  white-space: nowrap;
  opacity: 1 !important;
}

.menuItem:hover {
  background-color: aliceblue;
}

.itemTitle {
  font-weight: 600;
  font-size: 1.6rem;
  color: #596475;
  padding: 0rem 1rem;
  line-height: 1.2;
  white-space: nowrap;
}

.title {
  font-weight: 700;
  font-size: 1.8rem;
  color: #596475;
  padding: 0.6rem 1.8rem;
  line-height: 1.2;
}

.menuItemAboutUs {
  display: none;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .itemsContainer {
    width: 70vw;
    max-height: 70vh;
    overflow: auto;
  }

  .menuItemAboutUs {
    display: flex;
  }

  .headerContainer {
    display: flex;
  }
  .trademarkContainer {
    display: flex;
    flex-direction: column;
  }
}
