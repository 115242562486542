.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 1rem 2.4rem;
}

.icon,
.iconGrey {
  width: 2.8rem;
  height: 2.3rem;
  margin-inline-end: 1.6rem;
  align-self: center;
}

.iconGrey {
  filter: grayscale(1) invert(1) brightness(1.2);
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 3.6rem;
}

.title,
.titleGreen {
  color: #596475;
  font-weight: 400;
  font-size: 1.8rem;
}

.titleGreen {
  color: var(--color-main-green);
}

.subtitle {
  color: #838990;
  font-weight: 400;
  font-size: 1.4rem;
  padding-top: 0.2rem;
}

.mainContainer:first-child {
  padding-top: 2rem;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
}
.mainContainer:last-child {
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  padding-bottom: 2rem;
}

.mainContainer:hover {
  background-color: #f3f4fb;
}
/* 
.mainContainer:hover:first-child {
  padding-top: 2rem;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
}
.mainContainer:hover:last-child {
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  padding-bottom: 2rem;
} */
