.benefitItemMark {
  min-height: 5.1rem;
  height: 5.1rem;
  min-width: 6.8rem;
  max-width: 6.8rem;
  margin-top: -2.2rem;
  background-image: url("../../../assets/images/benefit_mark.svg");
  background-repeat: no-repeat;
  text-align: center;
  padding-top: 1.2rem;
  padding-left: 0.8rem;
  font-weight: 400;
  font-size: 1.5rem;
  color: white;
}
