.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5rem 3rem;
  gap: 1rem;
  width: 100%;
  background-color: white;
  border-radius: 50px;
}

.searchIcon {
  height: 2rem;
  width: 2rem;
}

.input {
  width: 100%;
  flex: 1;
  font-weight: 400;
  font-size: 1.8rem;
  color: black;
  background-color: transparent;
  white-space: nowrap;
}

.clearIcon {
  height: 1.6rem;
  width: 1.6rem;
  cursor: pointer;
}

/* clears the ‘X’ from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
