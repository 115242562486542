.header {
  height: 3.8rem;
  font-weight: 600;
  padding-top: 1rem;
  font-size: 1.7rem;
  color: #596475;
}

.entitiesContainer {
  padding-top: 1.5rem;
  padding-bottom: 4rem;
}
