.mainContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  z-index: 99;
}

.textInput {
  width: 30%;
  margin-top: 4rem;
  margin-bottom: 2rem;
  border-radius: 50px;
  padding: 1.1rem 2.2rem;
  background-color: var(--color-page);
}

.locationContainer {
  align-self: center;
  position: relative;
  margin-bottom: 2rem;
}

.locationButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  margin: auto;

  cursor: pointer;
  align-self: center;
  border: 2px solid #f3f4fb;
  backdrop-filter: blur(1px);
  border-radius: 50px;
  padding: 0.8rem 1.6rem;
}
.locationName {
  padding-top: 0.2rem;
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--color-main-green);
}
.locationEditBtn {
  height: 1.9rem;
  width: 1.9rem;
}
.entitiesContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  gap: 0.4rem;
}

.entityContainer {
  position: relative;
}

.entityContainer:hover .entityToolTip {
  display: flex;
}

.entityToolTip {
  min-width: 30rem;
  padding: 0.5rem;
  position: absolute;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--color-secondary-grey);
  display: none;
  margin-top: 0.5rem;
  box-shadow: 0px 2px 5px rgba(27, 27, 27, 0.1);
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-text);
  z-index: 999;
}

.tagHeader {
  padding-inline-start: 0.5rem;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.4rem;
  color: #596475;
  align-self: flex-start;
}

.tagsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.2rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  align-self: flex-start;
  flex-wrap: wrap;
}

.searchBarContainer {
  width: 60%;
  align-self: center;
  margin-top: 4rem;
  margin-bottom: 8rem;
}

.searchBar {
  background-color: #f3f4fb;
}

.locationBar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 10rem;
}

.entityTags {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.tagHeader {
  padding-inline-start: 0.5rem;
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #596475;
  align-self: flex-start;
}

.resultsNumber {
  font-weight: 400;
  font-size: 1.6rem;
  color: #596475;
  margin-top: 2.3rem;
}

.selectedCategoriesContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 0.4rem;

  align-self: flex-start;
  flex-wrap: wrap;
}

.clearAllButton {
  font-weight: 400;
  font-size: 1.4rem;
  color: #81bc45;
  align-self: center;
  margin: 0.5rem 1rem 0rem 1rem;
}
