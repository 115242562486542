.mainContainer {
  width: 100%;
  margin: 2rem 0rem;
  flex-direction: column;
  padding: 0rem 2rem 1.5rem 2rem;
  margin-bottom: 1rem;
  cursor: pointer;
  align-items: center;
  background-color: #d9ebc7;
  border-radius: 15px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 400;
  font-size: 18px;
  color: #596475;
}

.icon {
  width: 30%;
  height: auto;
  margin-top: -1rem;
  margin-bottom: 1rem;
}
