.paddedContent {
  display: flex;
  flex-direction: column;
  padding-inline: var(--common-padding-inline);
  width: 100%;
}

.visible {
  height: 25rem !important;
  transition: height 0.5s linear !important;
}

.visibleHideTags {
  height: 22rem !important;
  transition: height 0.5s linear !important;
}

.hidden {
  height: 0rem !important;
  transition: height 0.5s linear !important;
}
