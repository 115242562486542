.mainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 3rem;
}

.text {
  font-weight: 400;
  font-size: 1.6rem;
  color: #596475;
}

.input {
  width: 7.2rem;
  border-style: solid;
  border-width: 0.3px;
  border-color: #596475;
  border-radius: 4px;
  padding: 0rem 1rem;
  font-weight: 400;
  font-size: 1.6rem;
  color: #596475;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  gap: 0.6px;
}

.previousBtn,
.nextBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 3.4rem;
  background-color: var(--color-main-green);
}
.previousBtn:hover,
.nextBtn:hover {
  opacity: 0.8;
}

.previousBtn {
  border-start-start-radius: 8px;
  border-end-start-radius: 8px;
}
.nextBtn {
  border-start-end-radius: 8px;
  border-end-end-radius: 8px;
}

.btnDisabled {
  background-color: grey;
  pointer-events: none;
}

.btnIcon,
.btnIconFlipped {
  height: 1.1rem;
  width: auto;
}

.btnIconFlipped {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.error {
  border-color: rgb(250, 133, 133);
  border-width: 1.9px;
  position: relative;
  animation: shake 0.1s linear;
  animation-iteration-count: 3;
}

@keyframes shake {
  0% {
    left: -2px;
  }
  100% {
    right: -2px;
  }
}
