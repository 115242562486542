.mainContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.map {
  display: flex;
  flex-direction: column;
  height: 70rem;
  width: 35%;
  min-width: 35%;
  position: sticky;
  top: 0;
  padding-top: 7.5rem;
}

.resultsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4rem;
  margin-bottom: 4rem;
  margin-top: -3rem;
}

.entitiesContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.5rem;
  padding-top: 7.5rem;
}
