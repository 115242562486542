.mainContainer {
  width: 100%;
  margin-bottom: 3rem;
}

.entityButtonsContainer,
.entityButtonsContainerMobile {
  padding-inline: var(--common-padding-inline);
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin: 1rem 0rem;
}

.categoriesContainer {
  padding-inline: var(--common-padding-inline);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 2rem;
  margin: 0rem 9rem;
}

.entityButtonContainer {
  border-radius: 20px;
  width: auto;
}

.entityButtonInnerContainer {
  padding: 1.5rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
}

.entityButtonContainerSelected {
  background-color: var(--color-main-green);
  border-radius: 20px;
}

.entityIcon,
.entityIconGrey,
.entityIconSelected {
  height: 4rem;
  max-width: 4rem;
}

.entityIconSelected {
  filter: brightness(0) invert(1);
}

.entityIconGrey {
  filter: grayscale(1);
}

.entityName,
.entityNameGrey,
.entityNameSelected {
  color: var(--color-main-green);
  font-weight: 600;
  font-size: 1.4rem;
  margin-top: 0.6rem;
  letter-spacing: 0.1rem;
}

.entityNameSelected {
  color: white;
}

.entityNameGrey {
  color: #b9c3ce;
}

.comingSoonContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: relative;
}

.comingSoonTag {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(0.5px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.comingSoonText {
  position: relative;
  font-weight: 600;
  font-size: 4rem;
  text-align: center;
  color: #596475;
  height: min-content;
  margin: 1rem 2rem;
}

.categoriesTitle {
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
  color: #596475;
  margin-bottom: 1rem;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    margin-bottom: 1rem;
  }

  .entityButtonsContainerMobile {
    padding-inline: var(--mobile-padding-inline);
    width: 100%;
    overflow: scroll;
    justify-content: flex-start;
    margin: 2rem 0rem;
    position: relative;
  }

  .comingSoonText {
    text-align: start;
  }

  .categoriesTitle {
    text-align: start;
    margin-inline-start: 0.5rem;
    padding-inline-start: var(--mobile-padding-inline);
  }

  .categoriesContainer {
    padding-inline: var(--mobile-padding-inline);
    justify-content: flex-start;
    margin: 0rem 0rem;
  }

  .entityButtonInnerContainer {
    padding: 1.5rem 2.5rem;
  }

  /* four items */
  .tag:nth-child(15) ~ * {
    display: none;
  }

  four items li:first-child:nth-last-child(4),
  li:first-child:nth-last-child(4) ~ li {
    width: 25%;
  }
}
