.headingText {
  color: var(--color-text, #596475);
  font-size: 4rem;
  line-height: 4rem!important;
  z-index: 10;
}
@media (max-width: 991px) {
  .headingText {
    max-width: 100%;
  }
}
