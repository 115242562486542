.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 7rem 7rem 7rem;
  padding-inline: var(--common-padding-inline);
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 3.7rem;
  color: #596475;
  margin-bottom: 12rem;
}

.greenTitle {
  font-weight: 600;
  font-size: 4rem;
  color: #596475;
  padding: 2rem 5rem;
  background-color: #d9ebc7;
  margin-bottom: 12rem;
  border-radius: 10px;
  text-align: center;
}

.videoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border-radius: 20px;
  padding: 0rem 8rem;
  box-shadow: 0px 10px 100px 5px rgba(129, 188, 69, 0.2);
}

.video {
  margin-top: -5rem;
  height: 30rem;
  width: 90%;
  border-radius: 20px;
  box-shadow: 0px 10px 55px 8px rgba(0, 0, 0, 0.3);
  margin-bottom: 4rem;
}

.subtitle {
  font-weight: 600;
  font-size: 2.4rem;
  text-align: center;
  color: #596475;
  margin-bottom: 2rem;
}

.description {
  font-weight: 400;
  font-size: 2rem;
  text-align: center;
  color: #596475;
  margin-bottom: 4rem;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    padding: 4rem;
    padding-inline: var(--mobile-padding-inline);
    padding-bottom: 10rem;
  }
  .title {
    width: 95%;
    font-size: 3rem;
  }

  .greenTitle {
    font-size: 3rem;
    color: #596475;
    padding: 1rem 2.5rem;
    margin-bottom: 15rem;
  }

  .videoContainer {
    width: 100%;
    padding: 0rem 2.5rem;
  }

  .video {
    height: 20rem;
    width: 95%;
    margin-top: -10rem;
    box-shadow: 0px 5px 25px rgba(129, 188, 69, 0.4);
  }

  .subtitle {
    width: 100%;
    text-align: start;
  }

  .description {
    width: 100%;
    text-align: start;
  }
}
