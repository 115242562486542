.mainContainer {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
}

.paddedContent {
  padding-inline: var(--common-padding-inline);
  width: 100%;
}

.categoriesContainer {
  margin-bottom: 5.6rem;
}
