.mainContainer,
.mainContainerHoverable,
.mainContainerHovered {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
  border: 1px solid transparent;
}

.mainContainerHoverable:hover,
.mainContainerHovered {
  box-shadow: 0px 5px 25px rgba(129, 188, 69, 0.3);
}

.imageContainer {
  height: 12rem;
  cursor: pointer;
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.imageContainer,
.image {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.contentContainer {
  height: 12rem;
  display: flex;
  flex-direction: column;
  padding: 1.6rem 2rem;
  justify-content: space-between;
  cursor: pointer;
}

.tagsContainer {
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
  height: 2rem;
  margin-top: auto;
  overflow: hidden;
  gap: 2rem;
}

.businessesName {
  width: 100%;
  font-size: 2rem;
  line-height: 2rem;
  height: 2rem;
  font-style: normal;
  font-weight: 600;
  color: #586576;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
  text-align: start;
}

.businessesDescription {
  font-size: 1.3rem;
  line-height: 1.5rem;
  height: 3rem;
  font-style: normal;
  font-weight: 400;
  color: #838990;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}
