.mainContainer {
  width: 100%;
  margin-bottom: 2rem;
}

.mainHeader {
  font-weight: 600;
  font-size: 2rem;
  color: var(--color-text);
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  text-align: start;
}

.divider {
  width: 100%;
  height: 0.05rem;
  background-color: var(--color-main-green);
}

.ingredientsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.1rem 0; /* Applies margin to top and bottom */
  cursor: pointer; /* Indicates the element is clickable */
}

.menuDoubleColumn {
  column-gap: 6.4rem;
  column-count: 2;
}

.menuGrid {
  column-gap: 6.4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.categoryContainer {
  display: flex;
  flex-direction: column;
  display: inline-block;
  width: 100%;
}

.ingredientText {
  font-size: 2rem;
  justify-content: flex-start;
  display: flex;
}

.subingredientText {
  font-size: 1rem;
  justify-content: flex-start;
  font-size: 0.8rem; 
  font-weight: 400; /* Regular font */

}

.subsubingredientText {
  font-style: italic;
  font-size: 0.8rem;
  font-weight: 400; /* Regular font */
}

.categoryHeader {
  font-weight: 600;
  font-size: 1.6rem;
  color: var(--color-text);
  margin-bottom: 1rem;
}
.dishContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.dishImage {
  height: 5.9rem;
  width: 5.8rem;
  object-fit: cover;
  border-radius: 10px;
}
.dishHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dishName {
  font-weight: 500;
  font-size: 1.6rem;
  color: #586576;
  padding-bottom: 0.3rem;
}
.dishDescription {
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--color-secondary-grey);
  line-height: 1.2;
}
.dishDescriptionContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
}
.dishPrice {
  font-weight: 400;
  font-size: 1.6rem;
  color: #586576;
}
.ratingContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 0.2rem;
}
.dishRating {
  font-weight: 400;
  font-size: 1.2rem;
  margin-inline-start: 0.4rem;
  color: var(--color-text);
  line-height: 0.7;
}

.expandButton {
  display: none;
}

.menuPlaceholderContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.placeholderImage {
  margin-top: 3rem;
}

.placeholderText {
  font-style: normal;
  font-weight: 400;
  font-size: 1.8rem;
  color: #596475;
  margin-top: -2.5rem;
  margin-bottom: 3rem;
  text-align: center;
  padding: 0rem 3rem;
  line-height: 1.1;
}


.ingredientTextRegular {
  font-weight: 400; /* Regular font */
}

.ingredientTextBold {
  font-weight: 600; /* Medium font when there are subingredients */
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainHeader {
    margin-bottom: 3rem;
    font-size: 2.2rem;
  }

  .mainContainer {
    margin-top: 2rem;
  }

  .categoryContainer {
    margin-bottom: 1rem;
  }

  .menuDoubleColumn,
  .menuGrid,
  .menuContainerExpanded {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 40rem;
    overflow: hidden;
    position: relative;
    transition: all 1s ease;
  }

  .menuContainerExpanded {
    max-height: 400rem;
    max-width: 100%;
    transition: all 2s ease;
  }

  .divider {
  }


  .categoryHeader {
    font-size: 1.8rem;
  }

  .dishName {
    width: 100%;
    font-size: 1.8rem;
  }

  .dishDescription {
    font-size: 1.4rem;
  }

  .expandButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
    border-radius: 10px;
    gap: 1rem;
  }

  .expandButton p {
    font-weight: 400;
    font-size: 14px;
    color: #596475;
  }

  .expandButtonIcon {
  }

  .expandButtonIconExpanded {
    transform: scaleY(-1);
  }

  .bottomShade {
    width: 100%;
    position: absolute;
    bottom: 0;
    min-height: 6rem;
    z-index: 80;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 40%,
      rgba(255, 255, 255, 0.5) 60%,
      rgb(255, 255, 255) 99%
    );
  }
}
