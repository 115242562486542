.containerWrapper {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #f3f4fb;
  padding: 0;
  width: 100%;
}
.container {
  padding-inline: var(--common-padding-inline);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.title {
  color: var(--color-text, #596475);
  font-size: 3rem;
  font-weight: 600;
  z-index: 10;
}
.cardContainer {
  background-color: #fff;
  width: 100%;
  border-radius: 16px;
  box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.1);
  padding: 25px 50px;
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.imageContainer {
  height: 100px;
  width: 100px;
}
.image {
}
.cardContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.cardTitle {
  color: var(--Text, #596475);
  font-size: 2.5rem;
  font-weight: 400;
}
.cardSubtitle {
  color: var(--Text, #596475);
  font-size: 1.5rem;
  font-weight: 400;
}
.cardButton {
  display: inline-flex;
  height: 3rem;
  padding: 1rem 2rem;
  justify-content: center;
  align-items: center;
  gap: 20px;
  border-radius: 23px;
  background: var(--main-green, #81bc45);
  color: var(--white, #fff);
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  width: fit-content;
}
@media (max-width: 991px) {
  .container {
    padding-inline: var(--common-padding-inline);
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    width: 90%;
    margin: 2rem auto;
  }
  .cardContainer {
    background-color: #fff;
    width: 100%;
    border-radius: 16px;
    box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.1);
    padding: 25px 10px;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
}
