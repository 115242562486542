.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.textInput {
  width: 30%;
  margin-top: 4rem;
  margin-bottom: 2rem;
  border-radius: 50px;
  padding: 1.1rem 2.2rem;
  background-color: var(--color-page);
}

.locationContainer {
  align-self: center;
  position: relative;
  margin-bottom: 1rem;
}

.locationButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;

  cursor: pointer;
  align-self: center;
  border: 2px solid #f3f4fb;
  backdrop-filter: blur(1px);
  border-radius: 50px;
  padding: 0.8rem 1.6rem;
}
.locationName {
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--color-main-green);
}
.locationEditBtn {
}
.entitiesContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  overflow: scroll;
  padding-inline: var(--mobile-padding-inline) !important;
}

.tagHeader {
  padding-inline-start: 0.5rem;
  margin-top: 2rem;
  font-weight: 600;
  font-size: 1.4rem;
  color: #596475;
  align-self: flex-start;
}

.tagsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  overflow: scroll;
  -webkit-overflow-scrolling: auto;
  margin-top: 1rem;
  padding-inline: var(--mobile-padding-inline);
}

.separator {
  margin: 0rem 0.2rem;
  height: 100%;
  font-size: 0.2rem;
  background-color: var(--color-main-green);
  color: transparent;
}

.searchBarContainer {
  width: 90%;
  align-self: center;
  margin-top: 4rem;
  margin-bottom: 7rem;
}

.searchBar {
  background-color: #f3f4fb;
}

.locationBar {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 10rem;
}

.entityTags {
  flex: 1;
  padding: 1rem 2rem;
}

.tagHeader {
  padding-inline-start: 0.5rem;
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  color: #596475;
  align-self: flex-start;
}

.categoriesContainer {
  justify-content: flex-start;
}

.filtersButton {
  position: relative;
  background: #f3f4fb;
  border-radius: 50px;
  padding: 0rem 1rem;
}
.filtersText {
  padding: 1rem 1.6rem;
  font-size: 14px;
  color: #596475;
  white-space: nowrap;
}
.filtersNumber {
  position: absolute;

  top: 0;
  right: 0;
  padding: 0.4rem 0.7rem;
  background-color: var(--color-main-green);
  border-radius: 50px;
  color: white;
  font-size: 1.4rem;
}
