.ourLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
  padding-inline: var(--common-padding-inline);
  padding-top: 5rem;
  padding-bottom: 7rem;
}

.productLabel {
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  padding-inline: var(--common-padding-inline);
  background: linear-gradient(white 75%, var(--color-page) 25%);
  gap: 2rem;
}

.productLabel .image {
  width: 60rem;
  height: 40rem;
}

.restaurantsLabel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-inline: var(--common-padding-inline);
  background: linear-gradient(var(--color-page) 90%, white 10%);
  gap: 12rem;
}

.businessesLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline: var(--common-padding-inline);

  background-color: white;
  gap: 4rem;
  padding-top: 4.6rem;
  padding-bottom: 10.8rem;
}

.title {
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
  padding-bottom: 0.4rem;
}
.description {
  font-weight: 400;
  font-size: 2rem;
  color: #596475;
  padding-bottom: 0.8rem;
  text-align: flex-start;
}
.link {
  font-weight: 600;
  font-size: 2.2rem;
  color: var(--color-main-green);
  white-space: pre-wrap;
  line-height: 1.1;
}

.link:hover {
  color: #69a42e;
}

.productRow {
  display: flex;
  flex-direction: row;
}

.productContent {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-inline-start: 1.2rem;
}

.restaurantsContent {
  padding-top: 8rem;
}

.businessesContent {
  padding-top: 3rem;
}

.greenArrowImage {
  margin-top: 14%;
  transform: scaleY(-1);
}

.greenArrowProduct {
  margin-inline-start: auto;
  width: 15rem;
  transform: scaleY(-1);
}

.greenArrowRestaurant {
  margin-inline-end: auto;
  width: 15rem;
  margin-top: 2rem;
  margin-inline-start: -6rem;
  transform: scaleY(-1) scaleX(-1);
}

.greenArrowLabel {
  margin-inline-start: auto;
  width: 15rem;
  margin-top: -2rem;
  margin-inline-end: -3rem;
  transform: scaleY(-1);
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 48em) {
  .ourLabel {
    padding-inline: var(--mobile-padding-inline);
    padding-bottom: 5rem;
  }

  .productLabel {
    padding-inline: var(--mobile-padding-inline);
    flex-direction: column-reverse;
    background: linear-gradient(white 65%, var(--color-page) 35%);
    gap: 0rem;
    align-items: flex-end;
  }

  .restaurantsLabel {
    padding-inline: var(--mobile-padding-inline);
    padding-top: 3rem;
    flex-direction: column-reverse;
    background: linear-gradient(var(--color-page) 75%, white 25%);
    gap: 2rem;
  }

  .businessesLabel {
    padding-inline: var(--mobile-padding-inline);
    flex-direction: column;
    gap: 4rem;
    padding-top: 4.6rem;
    padding-bottom: 8rem;
  }

  .image {
    width: 100%;
    height: auto;
  }

  .productLabel .image {
    width: 100%;
    height: auto;
  }

  .greenArrowImage {
    height: 8rem;
    rotate: 120deg;
    margin-top: -2rem;
    transform: scaleY(-1);
    transform: scaleX(1);
    margin-inline-end: 4rem;
  }

  .restaurantsContent {
    padding-top: 2rem;
  }

  .productContent {
    padding-top: 0rem;
    padding-inline-start: 0rem;
  }

  .greenArrowProduct {
    width: 10rem;
    margin-top: -1rem;
    margin-inline-end: 2rem;
    transform: scaleY(1) rotate(0.35turn);
  }

  .greenArrowRestaurant {
    margin-inline-end: auto;
    width: 10rem;
    margin-top: -1rem;
    margin-bottom: 2rem;
    margin-inline-start: auto;
    margin-inline-end: 6rem;
    transform: scaleY(-1) scaleX(-1) rotate(0.57turn);
  }

  .greenArrowLabel {
    margin-inline-start: auto;
    width: 10rem;
    margin-top: 0rem;
    margin-bottom: -2rem;
    margin-inline-end: 5rem;
    transform: scaleY(-1) scaleX(-1) rotate(0.9turn);
  }
}
