.tooltip,
.tooltipHidden {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: -5rem;
  margin-inline-end: 30%;
  z-index: 1;
  opacity: 1;
  transition: opacity 1s;
}

.tooltipHidden {
  opacity: 0;
  transition: opacity 1s;
  display: none;
}

/* Tooltip text */
.tooltip_text {
  color: white;
  width: max-content;
  text-align: center;
  padding: 1.5rem 1.6rem;
  background-color: #ff527a;
  border-radius: 15px;
  font-weight: 400;
  font-size: 1.8rem;
}

.tooltip .tooltip_text::before {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the bottom of the tooltip */
  right: 15%;

  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #ff527a transparent;
}

.exit_icon {
  margin-inline-start: 1rem;
  height: 1.2rem;
  width: 1.2rem;
}

@media only screen and (max-width: 48em) {
  .tooltip,
  .tooltipHidden {
    margin-inline-end: 0%;
  }
}
