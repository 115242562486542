.mainContainer {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-bottom: 2rem;
}

.titleText,
.subtitleText {
  font-family: "AlmoniBold";
  color: white;
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 1rem;
  white-space: nowrap;
}

.divider {
  height: 0.3rem;
  width: 2rem;
  background-color: white;
  margin-bottom: 2rem;
}

.subtitleText {
  margin-bottom: 2rem;
}

.itemText {
  color: white;
  font-weight: 400;
  font-size: 2rem;
  white-space: nowrap;
  cursor: pointer;
}

.itemText:hover {
  color: white;
  opacity: 0.8;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.dropDown {
  margin-top: 1rem;
  top: 0;
  bottom: 0;
}

.locationContainer {
  width: 100%;
  align-self: center;
  top: 0;
}

.locationSelection {
  display: flex;
  flex-direction: column;
  padding: 1rem 2.4rem 1.5rem 2.4rem;
  background-color: white;
  gap: 2rem;
  width: 100%;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.buttonContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 20px;
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
}

.countryItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.flagLogo {
  width: auto;
  height: 3rem;
  object-fit: cover;
  border-radius: 2px;
}

.countryName {
  font-weight: 600;
  font-size: 1.6rem;
  color: #596475;
}

.versionTag {
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 1.4rem;
  color: white;
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 48em) {
  .titleText,
  .subtitleText {
    font-size: 2.5rem;
  }
}
