.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Almoni ML v5 AAA, -apple-system, Roboto, Helvetica, sans-serif;
  font-weight: 600;
  text-align: center;
  padding: 2rem;
  gap: 40px;
  width: 100%;
}
