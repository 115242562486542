.photoContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr;
  gap: 0.5rem;
  margin: 0rem auto;
}

.item {
  position: relative;
  cursor: move;
  height: 10rem;
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
  min-height: 10rem;
  min-width: 100%;
  max-height: 10rem;
  max-width: 100%;
  border-radius: 10px;
}

.item > * {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
}

.item:first-child {
  grid-row: 1 / 3;
  min-height: 20rem;
  max-height: 20rem;
}

.item:nth-child(3) {
  order: 3;
}

.item:nth-child(5) {
  order: 5;
}

/* Small devices (portrait tablets and large phones, 768px and down) */
@media only screen and (max-width: 48em) {
  .photoContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1.5fr 1fr 1fr;
  }

  .item {
    position: relative;
    cursor: move;
    height: 10rem;
    width: 100%;
    display: grid;
    grid-template: 1fr / 1fr;
    place-items: center;
    min-height: 10rem;
    min-width: 100%;
    max-height: 10rem;
    max-width: 100%;
    border-radius: 10px;
  }

  .item > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }

  .item:first-child {
    grid-column: 1 / 3;
    grid-row: 1 / 1;
    min-height: 15rem;
    max-height: 15rem;
  }

  .item:nth-child(3) {
    order: 2;
  }

  .item:nth-child(4) {
    order: 3;
  }
}
