.mainContainer {
  width: 100%;
  padding-inline: var(--common-padding-inline);
  margin-bottom: 5rem;
}

.gridContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.socialsContainer {
  max-width: 68%;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    padding-inline: var(--mobile-padding-inline);
  }

  .wrongInfoContainer {
    display: flex;
  }
}
