.mainContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.image {
  height: 39rem;
  width: auto;
  margin-bottom: -2rem;
}

.title {
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
  margin-bottom: 1rem;
}

.text {
  margin: auto;
  text-align: center;
  font-weight: 400;
  font-size: 1.6rem;
  color: #596475;
  white-space: pre-line;
  line-height: 1.2;

  margin-bottom: 2.5rem;
}
