.mainContainer {
  background-color: transparent !important;
  align-self: center;
  border: none;
}

.contentContainer {
  position: relative;
  background-color: #d9ebc7;
  align-self: center;

  width: 90%;
  max-width: 60rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 0rem 10rem 2rem 10rem;
  justify-content: space-between;
}

.title {
  font-size: 2.2rem;
  color: #596475;
  font-weight: 600;
  margin: 2.4rem 0rem 1rem;
  text-align: center;
}

.subtitle {
  font-size: 1.8rem;
  color: #596475;
  font-weight: 500;
  line-height: 2rem;
  margin-bottom: 2.4rem;
  text-align: center;
}

.input {
  width: 100%;
  padding: 1rem;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #596475;
  margin-bottom: 0.8rem;
}

.input::placeholder {
  color: #b9c3ce;
}

.button {
  margin-top: 0.3rem;
  width: 100%;
}

.cancelButton {
  width: 100%;
  font-weight: 600;
  font-size: 1.6rem;
  color: #596475;
  margin: 1.2rem auto;
}

.img {
  height: 21.3rem;
  width: auto;
  margin-top: -5rem;
}

.exitButton,
.exitButtonIL {
  position: absolute;
  margin-top: -3.5rem;
  margin-inline: 1rem;
  cursor: pointer;
}

.exitButton {
  left: 0;
}

.exitButtonIL {
  right: 0;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .contentContainer {
    padding: 0rem 4rem 2rem 4rem;
  }
}
