.mainContainer {
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding: 2.4rem;
  background: #c14b62;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.contentWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
}

.imageContainer {
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title {
  font-family: "AlmoniBold";
  font-weight: 700;
  font-size: 2.6rem;
  color: white;
  padding-bottom: 0.5rem;
}

.description {
  font-weight: 400;
  font-size: 1.6rem;
  color: white;
  white-space: pre-line;
  line-height: 1.2;
  padding-bottom: 1rem;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
}

.downloadButton {
  height: min-content;
  width: min-content;
  margin-top: 0.2rem;

  cursor: pointer;
}

.downloadButtonImage {
  width: 15rem;
  height: auto;
}
