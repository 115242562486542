/* embla.css */
.embla {
    overflow: hidden;
    width: 100%;
  }
  
  .embla__container {
    display: flex;
  }
  
  .embla__slide {
    position: relative;
    min-width: 200px; /* Adjust based on your logo size */
    padding: 10px;
  }
  
  .embla__slide__img {
    display: block;
    width: 100%;
    height: auto;
  }