.mainContainer {
  width: 100%;
  padding-inline: var(--common-padding-inline);
  margin-bottom: 1rem;
}

.gridContainer {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  grid-template-areas:
    "icons map"
    "description map"
    "benefits map"
    "menu map"
    "socials map"
    "review map";
  column-gap: 5rem;
}

.headerContainer {
}

.restaurantIcons {
  grid-area: icons;
  justify-content: flex-start;
  margin-bottom: 1.5rem;
  align-items: flex-start;
}

.descriptionContainer {
  grid-area: description;
}

.benefitsContainer {
  grid-area: benefits;
}
.mapContainer {
  margin-top: -5rem;
  padding-top: 5rem;
  grid-area: map;
}

.menuContainer {
  grid-area: menu;
}

.socialsContainer {
  grid-area: socials;
}

.wrongInfoContainer {
  display: none;
}

.reviewsContainer {
  grid-area: review;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    padding-inline: var(--mobile-padding-inline);
  }

  .gridContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      "icons "
      "description "
      "map"
      "benefits "
      "menu"
      "socials ";
    column-gap: 5rem;
  }

  .mapContainer {
    margin-top: 0rem;
    padding-top: 0rem;
    grid-area: map;
  }

  .restaurantIcons {
    font-size: 200%;
    margin-bottom: 3rem;
  }

  .restaurantIcons p {
    font-size: 1.6rem;
  }

  .wrongInfoContainer {
    display: flex;
  }
}
