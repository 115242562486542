.mainContainer {
  width: 100%;
  padding-inline: var(--common-padding-inline);
  margin-bottom: 5rem;
}

.title {
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
  padding-top: 3rem;
  padding-bottom: 0.4rem;
}

.socialsContainer {
  max-width: 68%;
}

.description {
  width: 80%;
  font-weight: 400;
  font-size: 1.6rem;
  color: var(--color-text);
  line-height: 1.4;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    padding-inline: var(--mobile-padding-inline);
  }

  .wrongInfoContainer {
    display: flex;
  }
}
