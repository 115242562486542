.mainContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 15px;
  box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
}

.imageContainer {
  height: 100%;
  width: 100%;
  padding: 3.1rem 6.7rem;
}

.img {
  height: 100%;
  width: 100%;
  opacity: 0.1;
}

.seeMoreTitle {
  cursor: pointer;
  position: absolute;
  width: 100%;
  top: 41%;
  text-align: center;
  font-size: 4px;
  font-weight: 400;
  font-size: 32px;
  color: var(--color-main-green);
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    align-self: center;
    min-width: 30rem;
    min-height: 100%;
    margin: 1rem 1rem;
  }
}
