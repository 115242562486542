.formContainer {
  font: 400 2rem/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
  margin: 0;
  padding: 0;
  width: 100%;
}
.mainContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5.6rem 5.6rem;
  background-color: #f6c5d0;
  background-repeat: no-repeat;
  background-size: cover;
  scroll-margin-top: 100px;
}

.formWrapper {
  border-radius: 40px;
  background: var(--white, #fff);
  padding-inline: calc(var(--common-padding-inline) - 5.6rem);
  padding-top: 5rem;
  padding-bottom: 5rem;
  width: 100%;
  position: relative;
}

.formTitle {
  color: var(--main-green, #81bc45);
  font-size: 4rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  margin: 0;
}

.formDescription {
  color: var(--color-text, #596475);
  text-align: center;
  margin: 17px 0 24px;
  font-size: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.formFields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
}

.inputField {
  border-radius: 14px;
  background: var(--page, #f3f4fb);
  padding: 1.8rem 2rem;
  border: none;
  width: 100%;
  font-size: 1.5rem;
}

.required {
  color: var(--main-beet, #ff527a);
}

.textareaWrapper {
  width: 100%;
}

.textarea {
  border-radius: 14px;
  background: var(--page, #f3f4fb);
  padding: 2.5rem 2rem;
  width: 100%;
  min-height: 150px;
  border: none;
  resize: vertical;
  font-size: 1.5rem;
  border-width: 0;
}
.textarea:focus {
  outline: none;
}
.textareaLimitContainer {
  align-self: flex-end;
}
.textareaLimitTitle {
  text-align: end;
  font-size: 1.5rem;
  color: #596475;
}
.submitButton {
  border-radius: 15.724px;
  background: var(--main-green, #81bc45);
  color: #fff;
  font-size: 2rem;
  font-weight: 600;
  padding: 15px 70px;
  border: none;
  cursor: pointer;
  width: fit-content;
  margin: 25px auto 0;
  min-width: 507px;
}

.submitButton:hover {
  opacity: 0.9;
}

.submitButton:focus {
  outline: 2px solid var(--main-green, #81bc45);
  outline-offset: 2px;
}

.loaderModal,
.errorModal {
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  border-radius: 40px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.submitTitle {
  position: relative;
  top: 45%;
  color: var(--main-green, #81bc45);
  font-size: 5rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  margin: 0;
  width: 75%;
  margin: 0 auto;
}
.errorContent {
  position: relative;
  top: 10%;
  font-size: 2rem;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  margin: 0;
  width: 75%;
  margin: 0 auto;
}

.submitContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 10rem;
}

.submitImage {
  height: 39rem;
  width: auto;
  margin-bottom: -2rem;
}

.submitTitle {
  font-weight: 600;
  font-size: 2.4rem;
  color: #596475;
  margin-bottom: 1rem;
}

.submitText {
  margin: auto;
  text-align: center;
  font-weight: 400;
  font-size: 1.6rem;
  color: #596475;
  white-space: pre-line;
  line-height: 1.2;

  margin-bottom: 2.5rem;
}

@media (max-width: 991px) {
  .formWrapper {
    padding: 24px 20px;
    border-radius: 20px;
  }
  .formDescription {
    text-align: center;
  }
  .formFields {
    grid-template-columns: 1fr;
  }

  .submitButton {
    min-width: unset;
    width: 100%;
    padding: 15px 20px;
  }

  .inputField,
  .textarea {
    padding: 15px 20px;
  }
  .mainContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 5.6rem 2rem;
    background-color: #f6c5d0;
    background-repeat: no-repeat;
    background-size: cover;
    scroll-margin-top: 100px;
  }
}
