.mainContainer {
  width: 100%;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.description {
  width: 80%;
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-text);
  line-height: 1.2;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
}

.icon {
  height: 2rem;
  width: 2rem;
  margin-inline-end: 1.6rem;
  align-self: flex-start;
}

.location {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-main-green);
}

.scheduleRow,
.rowBold {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  align-items: flex-start;
}

.rowBold {
  font-weight: 800;
}

.openHours {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-text);
}
.phoneNumber {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-main-green);
  text-align: center;
}

.reservation {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-main-green);
  padding-top: 0.5rem;
}

.divider,
.bottomDivider {
  height: 0.05rem;
  background-color: var(--color-main-green);
}

.bottomDivider {
  display: none;
}

.distance {
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-text);
}

.openingDay {
  font-weight: inherit;
  font-size: 1.4rem;
  color: var(--color-text);
  line-height: 1;
  min-width: 7rem;
}
.openingHour {
  margin-inline-start: 1rem;
  font-weight: inherit;
  font-size: 1.4rem;
  color: var(--color-text);
  line-height: 1.2;
}

.noInfo {
  font-weight: inherit;
  font-size: 1.4rem;
  color: var(--color-text);
  line-height: 1.2;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer p {
    font-size: 1.6rem;
  }

  .description {
    width: 100%;
  }

  .bottomDivider {
    display: flex;
    margin-top: 2rem;
  }

  .openingDay {
    min-width: 8rem;
  }
}
