.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
}

.wrongInfoContainer {
  display: flex;
  flex-direction: row;
  padding: 1rem;
  background: #f3f4fb;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
}

.wrongInfoText {
  font-weight: 400;
  font-size: 1.2rem;
  color: var(--color-main-green);
}

.lastUpdateText {
  padding: 0.8rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: #838991;
  text-align: center;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    margin-bottom: 2rem;
  }
}
