.cardItemContainer {
  width: 25%;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  position: relative;
}
.contentContaienr {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0.5rem;
  align-items: center;
  align-content: center;
  background-color: #fff;
  width: 100%;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
  z-index: 6;
}
.step {
  color: var(--white, #fff);
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: -5px;
}
.stepCount {
  color: var(--white, #fff);
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
}
.icon {
  width: 20px;
  height: 20px;
  filter: invert(38%) sepia(8%) saturate(369%) hue-rotate(175deg)
    brightness(95%) contrast(85%);
  object-fit: contain;
}
.title {
  color: var(--main-green, #81bc45);
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  width: 85%;
}
.titleLast {
  color: var(--main-beet, #ff527a);
  text-align: center;
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  width: 85%;
}
.subtitle {
  color: var(--secondary-grey, #838990);
  text-align: center;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  width: 85%;
}
.action {
  text-decoration: underline;
  color: var(--main-green, #81bc45);
  cursor: pointer;
  font-weight: 600;
}
.upperLip {
  width: 80%;
  height: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 2;
  background: linear-gradient(
    90deg,
    #75b238 -3.78%,
    #a7e36b 45.38%,
    #72af35 103.1%
  );
}
.upperLipLast {
  width: 80%;
  height: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  z-index: 2;
  background: linear-gradient(
    90deg,
    #cd3d5e -2.18%,
    #ff829e 49.66%,
    #cd3d5e 103.1%
  );
}

.bottomLip {
  z-index: 2;
  width: 80%;
  height: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(
    90deg,
    #75b238 -3.78%,
    #a7e36b 45.38%,
    #72af35 103.1%
  );
}
.bottomLipLast {
  z-index: 2;
  width: 80%;
  height: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: linear-gradient(
    90deg,
    #cd3d5e -2.18%,
    #ff829e 49.66%,
    #cd3d5e 103.1%
  );
}
@media (max-width: 991px) {
  .cardItemContainer {
    width: 75%;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    position: relative;
  }
}
