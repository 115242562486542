.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7rem 7rem 0rem 7rem;
  padding-inline: var(--common-padding-inline);
  gap: 50px;
}
.intro {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-bottom: 50px;

}
.title {
  color: var(--color-text, #596475);

  /* Header */
  font-family: "AlmoniRegular";
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 105.263% */
  text-align: start;
}
.description {
  color: var(--color-text, #596475);
  font: 400 2.2rem AlmoniRegular, -apple-system, Roboto, Helvetica, sans-serif;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  text-align: start;
}
@media (max-width: 991px) {
  .intro {
    margin-bottom: 0;
    width: 90%;
    margin: 2rem auto;
  }
}
