.mainContainer {
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.categoriesContainer,
.categoriesContainerOpen {
  width: 100%;
  max-height: 3.6rem;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.categoriesContainerOpen {
  max-height: fit-content;
}

.moreButtonContainer {
  height: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  padding: 0.6rem 1.5rem;
  margin-inline: 2px;
  margin-block: 3px;
}

.moreButtonText {
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--color-main-green);
  white-space: nowrap;
}

.moreButtonIcon,
.moreButtonIconSelected {
  width: 1rem;
  height: 2rem;
  margin: 0rem 0.5rem;
  rotate: 20;
}

.moreButtonIconSelected {
  transform: scaleY(-1);
}
@media only screen and (max-width: 48em) {
  .mainContainer {
    flex-direction: column;
  }
  .moreButtonContainer {
    align-self: center;
  }
  .categoriesContainer,
  .categoriesContainerOpen {
    justify-content: center;
  }
}
