.container {
  border-radius: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #f3f4fb;
  padding: 0;
  width: 100%;
  margin-top: 10%;
}
.containerWrapper {
  padding-inline: var(--common-padding-inline);
}

.contentWrapper {
  align-self: start;
}

.mainSection {
  gap: 20px;
  display: flex;
}

.imageColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  color: var(--color-text, #596475);
  font: 400 28px/1 AlmoniRegular, -apple-system, Roboto, Helvetica, sans-serif;
}

.surveyImage {
  object-fit: contain;
  object-position: center;
  width: 100%;
  border-radius: 0;
  margin-top: -20%;
}

.imageCaption {
  margin: 31px 0 0 15px;
  font-size: 2.2rem;
}

.contentColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 100%;
  margin-left: 20px;
  position: relative;
}

.textContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  color: var(--color-text, #596475);
  font: 600 22px/25px AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
  padding: 2%;
  margin-top: 10px;
}

.title {
  color: var(--main-beet, #ff527a);
  font-size: 3rem;
  font-weight: 600;
  text-align: start;
  align-self: start;
}

.subtitle {
  text-align: start;
  font-size: 2.5rem;
  font-weight: 600;
}

.description {
  text-align: start;
  font-weight: 400;
  font-size: 2rem;
}

.highlightBox {
  border-radius: 19px;
  background-color: rgba(211, 81, 119, 1);
  align-self: center;
  z-index: 998;
  width: 65%;
  max-width: 100%;
  color: #fff;
  font-weight: 400;
  padding: 20px 30px;
  font-size: 2rem;
}
.highlightArrow {
  background-color: rgba(211, 81, 119, 1);
  align-self: center;
  height: 75px;
  width: 75px;
  rotate: 45deg;
  margin-top: -45px;
  border-radius: 7px;
  z-index: 997;
}
.highlightContainer {
  display: flex;
  flex-direction: column;
  margin-right: -16%;
  margin-bottom: -3.5rem;
  top: 0%;
  transition: all 0.5s ease-in-out;
  position: relative;
  z-index: 998;
}

.highlightContainer.animated {
  animation: bounceMargin 0.2s ease-in-out;
}

@keyframes bounceMargin {
  0% {
    bottom: -10px;
  }

  50% {
    bottom: -30px;
  }

  100% {
    bottom: -10px;
  }
}

.statsContainer {
  border-radius: 37px;
  margin-top: 10px;
  margin-bottom: 30px;
  padding: 33px 70px 15px;
  background-color: #fff;
  position: relative;
}

.statsGrid {
  gap: 20px;
  margin-top: -50px;
  display: flex;
  /* margin-left: 50px; */
  z-index: 998;
}

.statsColumn {
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 82%;
}

.statsHeader {
  display: flex;
  margin-top: 11px;
  width: 100%;
  flex-direction: column;
  align-items: start;
}

.statsTitle {
  width: 80%;
  color: var(--main-green, #81bc45);
  font: 600 3rem/1 AlmoniRegular, -apple-system, Roboto, Helvetica, sans-serif;
}

.statsSubtitle {
  width: 80%;
  color: var(--secondary-grey, #838990);
  margin-top: 4px;
  font: 400 2rem/1 AlmoniRegular, -apple-system, Roboto, Helvetica, sans-serif;
}
.topLeaf {
  position: absolute;
  transform: rotate(-180deg) scaleX(1) scaleY(-1);
  right: 0;
  bottom: 0;
  border-radius: 37px;
}
.bottomLeaf {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 37px;
}
.img {
  border-radius: 37px;
  width: 182px;
  height: 198px;
  flex-shrink: 0;
}

@media (max-width: 991px) {
  .bottomLeaf {
    position: absolute;
    left: 0;
    opacity: 0;
    bottom: 0;
    border-radius: 37px;
    transition: all 0.5s ease-in-out;
  }
  .topLeaf {
    opacity: 0;
    transition: all 0.5s ease-in-out;
    position: absolute;
    transform: rotate(-180deg) scaleX(1) scaleY(-1);
    right: 0;
    bottom: 0;
    border-radius: 37px;
  }
  .contentWrapper {
    max-width: 100%;
    /* margin-right: -18px; */
  }

  .container {
    border-radius: 0;
    display: flex;
    flex-direction: column;
    margin: 0;
    background-color: #f3f4fb;
    padding: 0;
    width: 100%;
    margin-top: 20%;
  }

  .mainSection {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
  .highlightContainer {
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
    z-index: 998;
    width: 100%;
    margin-bottom: 10%;
  }
  .highlightArrow {
    display: none;
  }
  .imageColumn {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .imageContainer {
    margin-top: 40px;
    align-items: center;
    gap: 10px;
  }

  .imageCaption {
    margin: 0 10px;
  }
  .surveyImage {
    object-fit: contain;
    object-position: center;
    width: 40%;
    border-radius: 0;
    margin-top: -20%;
    align-self: center;
  }
  .contentColumn {
    width: 100%;
    margin-left: 0;
    margin: 0;
  }

  .textContent {
    max-width: 100%;
    margin-top: 40px;
  }

  .subtitle {
    max-width: 100%;
  }

  .description {
    max-width: 100%;
  }

  .highlightBox {
    max-width: 100%;
    margin-top: 40px;
    padding: 20px 20px;
  }

  .statsContainer {
    max-width: 100%;
    /* margin-right: 6px; */
    padding: 0 20px;
  }

  .statsGrid {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }

  .statsColumn {
    width: 100%;
  }

  .statsHeader {
    max-width: 100%;
    margin-top: 40px;
  }
}
