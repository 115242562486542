.mainContainer {
}

.contentContainer {
  display: grid;
  grid-template-columns: 2fr 2fr 1fr 20rem;
  background-color: var(--color-main-green);
  width: 100%;
  overflow: hidden;
  grid-template-areas: "banner list buttons logo";
}

.buttonsContainer {
  margin-top: 3rem;
  grid-area: buttons !important;
  padding-inline-end: 4rem;
}

.logoContainer {
  grid-area: logo;
  height: 100%;
  max-width: 35rem;
  background-color: white;
  border-start-start-radius: 100%;
  border-end-start-radius: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0rem;
  margin-inline-end: -13rem;
}

.bannerContainer {
  grid-area: banner;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem 4rem;
  padding-bottom: 4rem;
}

.versionContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  margin-top: 4rem;
}

.logo {
  padding: 1rem;
  max-width: auto;
  height: 16rem;
  margin-inline-start: 2rem;
  align-self: center;
}

.logo:hover {
  transform: scaleX(1.1) scaleY(1.1);
}

.listContainer {
  grid-area: list;
  width: 100%;
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 4.7rem;
  padding: 3rem 5rem 5rem 2rem;
}

.footerCat {
  width: 100%;
  height: auto;
  margin-inline-start: auto;
  max-width: 35rem;
  align-self: center;
}

.rightsText {
  text-align: center;

  font-weight: 600;
  font-size: 1.5rem;
  color: #ffffff;
}

.versionTag {
  font-weight: 500;
  font-size: 1.4rem;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 62em) {
  .mainContainer {
    grid-template-columns: 1fr 3fr;
  }

  .listContainer {
    padding: 3rem;
    gap: 2rem;
  }

  .bannerContainer {
    display: none;
  }
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .contentContainer {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    width: 100%;
  }

  .logoContainer {
    grid-area: logo;
    height: 100%;
    max-width: 35rem;
    background-color: white;
    border-start-start-radius: 100%;
    border-end-start-radius: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0rem;
    margin-inline-end: -13rem;
  }

  .logoContainer {
    width: 100%;
    height: 40rem;
    max-width: 100%;
    border-start-end-radius: 100%;
    border-start-start-radius: 100%;
    border-end-start-radius: 0rem;
    justify-content: center;
    margin-bottom: -20rem;
    margin-inline-start: 0rem;
  }

  .logo {
    margin-inline-end: 0rem;
    align-self: flex-start;
    margin-top: 3rem;
  }

  .listContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    margin-bottom: 0rem;
  }

  .footerCat {
    display: none;
  }

  .contactUsContainer {
    height: 20rem;
    margin: 0rem 3rem;
    margin-bottom: 4rem;
  }

  .buttonsContainer {
    padding-inline-start: 4rem;
  }

  .bannerContainer {
    grid-area: banner;
    margin: auto 0rem;
    margin-top: 2rem;
  }

  .versionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }

  .bannerContainer {
    grid-area: banner;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 2rem 2rem 1rem 2rem;
    padding-bottom: 0rem;
  }
}
