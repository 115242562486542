.tagContainer,
.tagContainerSelected,
.tagContainerDisabled {
  padding: 0.6rem 1.5rem;
  border-radius: 50px;
  background-color: var(--color-light-green);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}


.tagContainerSelected {
  background-color: var(--color-main-green);
}

.tagContainerDisabled {
  background-color: #f3f4fb;
  pointer-events: none;
}

.tagText,
.tagTextSelected,
.tagTextDisabled {
  font-weight: 400;
  font-size: 1.3rem;
  color: #69a42e;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  padding-top: 0.3rem;
}

.tagTextSelected {
  color: var(--color-light-green);
}

.tagTextDisabled {
  color: #b9c3ce;
}


.iconEnd {
  height: 0.8rem;
  margin-top: 0.23rem;
  margin-inline-start: 0.5rem;
}



.iconSelected {
  height: 1.5rem;
  filter: brightness(100);
  margin-inline-end: 0.5rem;
}

.icon,
.iconDisabled {
  height: 1.5rem;
  margin-inline-end: 0.5rem;
}