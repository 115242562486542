.tagContainer,
.tagContainerDisabled,
.tagContainerSelected {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.6rem 1.5rem;
  border-radius: 50px;
  background-color: white;
  border: 1px solid var(--color-main-green);
  cursor: pointer;
}



.tagContainerSelected {
  background-color: var(--color-main-green);
}

.tagContainerDisabled {
  background-color: #f3f4fb;
  pointer-events: none;
  border: none;
}

.tagText,
.tagTextSelected,
.tagTextDisabled {
  font-weight: 400;
  font-size: 1.3rem;
  color: var(--color-main-green);
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  padding-top: 0.3rem;
}

.tagTextSelected {
  color: var(--color-light-green);
}

.tagTextDisabled {
  color: #b9c3ce;
}

.icon,
.iconSelected,
.iconDisabled {
  margin-inline-end: 0.5rem;
}

.iconSelected {
  filter: brightness(100);
}

.iconDisabled {
  filter: grayscale(1) brightness(1.2);
}
