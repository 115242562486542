.mainContainer {
  width: 100%;
  padding-top: 2rem;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 3rem;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0rem 1.5rem;
}

.mainHeader {
  font-weight: 600;
  font-size: 2rem;
  color: #596475;
  line-height: 1.2;
  padding-inline-end: 0.5rem;
}

.row {
  display: flex;
  flex-direction: row;
}

.sectionContainer {
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.sectionHeader {
  font-weight: 600;
  font-size: 1.6rem;
  color: #838990;
  margin-bottom: 1rem;
}

.stepContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.stepNumberContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  background-color: #f3f4fb;
  border-radius: 10px;
}

.stepNumber {
  text-align: center;
  color: #838990;
  font-weight: 600;
  font-size: 1rem;
}

.stepDetails {
  flex: 1;
  font-weight: 400;
  font-size: 1.4rem;
  color: #838990;
  padding-inline-start: 1rem;
  line-height: 1.2;
}

.creditHeader {
  margin-top: 4rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: #596475;
}

.creditLink {
  margin-top: 4rem;
  font-weight: 400;
  font-size: 1.4rem;
  color: var(--color-main-green);
  text-decoration: underline;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .stepDetails {
    font-size: 1.6rem;
  }
}
