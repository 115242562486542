.mainContainer,
.mainContainerLight,
.mainContainerTranslucent,
.mainContainerNoBackground {
  background: var(--color-main-green);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  padding: 0.8rem 2.5rem;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.mainContainer:hover {
  opacity: 0.8;
}

.mainContainerLight {
  background: #ddf5d2;
}

.mainContainerTranslucent {
  background: transparent;
  padding: 0.7rem 2.4rem;
  border: 1px solid #81bc45;
}

.mainContainerNoBackground {
  background: transparent;
}

.icon {
  margin-inline-end: 0.8rem;
  height: 1.5rem;
  width: 1.5rem;
}

.text {
  font-weight: 600;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
  color: white;
  white-space: nowrap;
  cursor: pointer;
}

.textLight {
  font-weight: 600 !important;
  color: var(--color-main-green);
}

.textSlim {
  font-weight: 500;
  font-size: 1.6rem;
  letter-spacing: normal;
}
