.promotionWrapper {
  display: flex;
  width: 100%;
}

.iconWrapper {
  stroke-width: 1px;
  background-color: #fff;
  border-radius: 50%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 103px;
  height: 103px;
  stroke: var(--light-green, #effce3);
  margin: auto -41px auto 0;
  padding: 0 20px;
  border: 1px solid rgba(239, 252, 227, 1);
}

.promotionIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 62px;
}

.contentWrapper {
  border-radius: 20px;
  box-shadow: 0px 10px 25px 0px rgba(27, 27, 27, 0.1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-family: AlmoniRegular, -apple-system, Roboto, Helvetica, sans-serif;
  color: var(--color-text, #596475);
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  padding:  3rem 5rem;
  max-width: 80%;
}

.promotionTitle {
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  align-self: start;
}

.promotionDescription {
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
  margin-top: 4px;
}

@media (max-width: 991px) {
  .contentWrapper {
    max-width: 100%;
    padding: 3rem;
  }

  .promotionDescription {
    max-width: 100%;
  }
  .iconWrapper {
  display: none;
  }
}
