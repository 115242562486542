.mainContainer {
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0px 10px 25px rgba(27, 27, 27, 0.1);
  }
  
  .imageContainer {
    height: 12rem;
  }
  
  .image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    cursor: pointer;
  }
  
  .imageContainer,
  .image {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  
  .contentContainer {
    height: 12rem;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 1.6rem 2rem;
    cursor: pointer;
  }
  
  .employerTitle {
    font-weight: 600;
    font-size: 1.6rem;
    max-height: 2rem;
    line-height: 2rem;
    margin-bottom: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: #586576;
  }
  
  .employerDescription {
    font-size: 1.2rem;
    line-height: 1.5rem;
    max-height: 4.5rem;
    font-style: normal;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    color: #586576;
  }
  
  .tagContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    flex-wrap: wrap;
    height: 2rem;
    overflow: hidden;
  }
  
  .tag {
    height: 2rem;
    font-weight: 400;
    font-size: 1.4rem;
    margin-inline-end: 0.5rem;
    white-space: nowrap;
    color: var(--color-main-green);
  }
  