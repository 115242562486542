.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 20px;
  width:100%;
  gap: 20px;
  
}

@media (max-width: 991px) {
  .contaienr {
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
