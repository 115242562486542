.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
}

.title {
  width: 40%;
  font-weight: 500;
  font-size: 1.8rem;
  color: #596475;
  text-align: center;
  line-height: 1.5;
  margin-top: 2.4rem;
  margin-bottom: 5rem;
}
