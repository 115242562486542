.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 5.6rem 0rem;
  padding-inline: var(--common-padding-inline);
  scroll-margin-top: 100px;

  /* background-color: rgba(243, 244, 251, 1); */

}

.wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.innerWrapper {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.heading {
  color: var(--color-text, #596475);
  align-self: start;
  font: 600 38px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.subtitle {
  color: var(--color-text, #596475);
  align-self: flex-start;
  margin-top: 7px;
  font: 400 22px/25px AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.content {
  align-self: flex-start;
  display: flex;
  margin-top: 19px;
  width: 100%;
  max-width: 1025px;
  gap: 19px;
  flex-wrap: wrap;
}
.contentContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.imageContainer {
  display: flex;
  flex-direction: column;
}

.image {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 129px;
  align-self: flex-start;
  margin-top: 24px;
}

.imageSecondary {
  composes: image;
  margin-top: 24px;
  align-self: flex-start;
}

.textContent {
  align-self: end;
  display: flex;
  margin-top: 23px;
  flex-direction: column;
  color: var(--color-text, #596475);
  flex-grow: 1;
  flex-basis: 0;
  width: fit-content;
  font: 600 28px/1 AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}

.mainTitle {
  align-self: start;
  color: var(--color-text, #596475);

  /* Sub-header */
  font-family: "AlmoniRegular";
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 114.286% */
}

.description {
  color: var(--color-text, #596475);

  /* Body txt */
  font-family: "AlmoniRegular";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 113.636% */
}

.secondaryTitle {
  align-self: start;
  margin-top: 30px;
}

.footer {
  color: var(--secondary-grey, #838990);
  align-self: end;
  margin-top: 24px;
  font: 400 14px/15px AlmoniRegular, -apple-system, Roboto, Helvetica,
    sans-serif;
}

@media (max-width: 991px) {
  .wrapper {
    max-width: 100%;
    padding: 0 20px;
  }

  .subtitle,
  .content,
  .textContent,
  .mainTitle,
  .description,
  .secondaryTitle,
  .footer {
    max-width: 100%;
    text-align: center;
    align-self: center;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .image{
    align-self: center;
  }
  .heading{
    align-self: center;
  }
}
