.mainContainer {
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  background-color: transparent;
  position: sticky;
  height: min-content;
  top: 0;
}
.headerContainer {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: var(--color-main-green);
}
.headerText {
  font-weight: 600;
  font-size: 2.2rem;
  color: white;
  padding: 1rem 2rem;
}
.ingredientsContainer {
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0px 7px 42px rgba(164, 164, 164, 0.25);
  padding: 1rem 1.7rem;
  gap: 1rem;
}
.sectionContainer {
}

.sectionHeader {
  font-weight: 600;
  font-size: 1.5rem;
  color: #838990;
  padding-bottom: 1rem;
}
.ingredientContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.1rem;
  color: #838990;
}
.leafIcon {
}
.ingredientName {
  font-weight: 400;
  font-size: 1.4rem;
  padding-inline-start: 1rem;
  color: #838990;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .ingredientName {
    font-size: 1.6rem;
  }

  .wrongInfoContainer {
    display: none !important;
  }
  .mainContainer {
    position: relative;
  }
}
