.mainContainer {
  width: 100%;
  height: min-content;
  top: 0;
  position: sticky;
  padding-bottom: 2rem;
}

.map {
  width: 100%;
  height: 50rem;
  padding-top: 2rem;
}

@media only screen and (max-width: 48em) {
  .mainContainer {
    display: flex;
    position: relative;
  }

  .map {
    height: 25rem;
  }

  .wrongInfoContainer {
    display: none;
  }
}
