.inputContainer {
  width: 100%;
  margin-bottom: 0.8rem;
}

.input {
  width: 100%;
  padding: 1rem;
  background-color: #f3f4fb;
  border-radius: 10px;
  font-size: 1.6rem;
  font-weight: 400;
  color: #596475;
  margin-bottom: 0.4rem;
  border: 1px solid transparent;
}

.input::placeholder {
  color: #b9c3ce;
}

.input:focus {
  border: 1px solid #b9c3ce;
}

.inputError {
  background-color: #ffdfe6;
}

.inputError::placeholder {
  color: #596475;
}

.errorMessage {
  color: #ff4a4a;
  font-weight: 400;
  font-size: 1.4rem;
}
