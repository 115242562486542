.mainContainer {
  width: 100%;
  /* padding-inline: var(--common-padding-inline); */
}

/* Medium devices (landscape tablets) */
@media only screen and (max-width: 48em) {
  .mainContainer {
    width: 100%;
    /* padding-inline: var(--mobile-padding-inline); */
  }
}
