.mainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2rem;
}

.topDivider,
.bottomDivider {
  width: 100%;
  height: 0.05rem;
  background-color: var(--color-main-green);
  margin-bottom: 2rem;
}

.websiteLink {
  margin-bottom: 1rem;
  font-weight: 400;
  font-size: 1.4rem;
  text-decoration: underline;
  color: var(--color-main-green);
  align-self: flex-start;
}

.mediaButtons {
  align-items: center;
}

/* Small devices (portrait tablets and large phones) */
@media only screen and (max-width: 48em) {
  .topDivider {
  }

  .websiteLink {
    font-size: 1.6rem;
  }
}
